import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const MyAccount = () => {
  const {plan} = useSelector(state=>state.auth);

  console.log(plan);
  return (
    <div className=" bg-[#F4F6F9]">
      <div className=" container  px-16  py-12 mx-auto">
        <div className=" bg-white p-6 rounded-md">
          <div className=" flex justify-between gap-10 ">
            <div className=" w-[65%]">
              <h1 className=" text-black text-2xl font-bold">
                My Account Settings
              </h1>
              <p className=" text-black text-sm pt-3 font-normal">
                In your Account Setting Area, you can manage all your personal
                data and information. You can edit your Contact Information,
                Change Membership, or Add an additional payment method.
              </p>

              <div className=" grid gap-6 grid-cols-2 mt-4">
                <div className=" border bg-[#F4F6F9] flex p-4 gap-3 rounded-lg">
                  <div>
                    <div className=" w-8 h-8 rounded-full flex justify-center items-center bg-[#A46A2D]">
                      <FaRegUser color="white" />
                    </div>
                  </div>
                  <div>
                    <h3 className=" font-semibold">Personal Account Holder</h3>
                    <p className=" font-medium text-gray-400 pt-1">
                      Please provide us with your personal Details and how we
                      can rech you
                    </p>
                  </div>
                </div>
                <Link to={'/account_address_book'} className=" border bg-[#F4F6F9] flex p-4 gap-3 rounded-lg">
                  <div>
                    <div className=" w-8 h-8 rounded-full flex justify-center items-center bg-[#A46A2D]">
                      <FaRegUser color="white" />
                    </div>
                  </div>
                  <div>
                    <h3 className=" font-semibold">Delivery Address</h3>
                    <p className=" font-medium text-gray-400 pt-1">
                      Enter all your delivery addresses and shipping options
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div className=" w-[35%]">


              <div   className=" mb-6 p-4 rounded-lg text-center border">
                <h2 className="  text-lg font-semibold">Basic Plan</h2>
                <p className=" text-black text-lg font-semibold">Your current plan is Basic, Upgrade your plan to enjoy with insurance</p>
              </div>

              <div  className=" border  rounded-lg p-4">
                <h2 className=" pb-3 text-center text-black font-bold text-2xl">PREMIUM Plan</h2>
                  <ul className=" gap-2 p-0 flex   mt-3 flex-col">
                    {plan?.features?.map((item,index)=>{
                      return (
<li className=" flex gap-3 items-center text-black font-semibold"> <FaCheckCircle color="#84592C" />{item?.text}</li>

                      )
                    })}
                    
                   
                  </ul>
                  <button className="px-6 mx-auto mt-10 text-center text-sm uppercase rounded-sm py-2.5 flex items-center justify-center gap-1 bg-[#A36A2D] text-white">
                  Upgrade Now
                  </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
