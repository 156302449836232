import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addPlan } from "../../store/authSlice";

const Pricing = ({
  data,
  getData,
  price,
  duration,
  background,
  shadow = "shadow-md",
  buttonContent,
  currency = "$",
  subTitle,
  priceText,
  headerText,
  Link_package
}) => {


  const navigate = useNavigate();


   const dispatch = useDispatch()

  return (
    <div className={`w-96 min-h-screen bg-white flex flex-col text-primary ${shadow} transition-shadow duration-300 p-2.5 hover:shadow-lg mb-4`}>
      <div className={`h-16 bg-cover bg-no-repeat bg-center text-center flex justify-center items-center text-2xl font-semibold ${background}`}>
        {headerText}
      </div>
      {price !== undefined && (
        <div className="h-1/3 flex flex-col justify-center items-center mt-12">
          <div className="flex">
            <div className="mt-2 mr-1">
              <span className="">{currency}</span>
            </div>
            <div>
              <span className="text-6xl">{price}</span>
            </div>
            {price > 0 && (
              <div className="mt-8">
                <span className=" font-medium">{duration}</span>
                {/* === "m" ? "/ mo" : "/ yr" */}
              </div>
            )}
          </div>
          {/* {subTitle && (
            <div className="uppercase text-center my-2">
              <h4>{subTitle}</h4>
            </div>
          )} */}
          {/* {priceText && (
            <div className="flex justify-center w-3/5 text-center font-light">
              <h5>{priceText}</h5>
            </div>
          )} */}
        </div>
      )}
      {buttonContent && (
        <Link to={'/register'} onClick={() => dispatch(
          addPlan(getData)
        )}
         className="flex justify-center items-center my-8">
          <button className=" rounded-md border border-primary w-11/12 h-14 bg-transparent text-lg cursor-pointer transition-all duration-200 hover:bg-primary  px-6 text-center hover:bg-[#A36A2D]  hover:text-white text-black font-medium border-[#A36A2D]  py-2 flex items-center justify-center gap-1">
            {buttonContent}
          </button>
        </Link>
      )}
      {data && (
        <div>
          <ul className="list-none   pl-10">
            {data.map((dt, index) => (
              <li key={index} className="flex items-center mb-4">
                {dt.value ? (
                  <FaCheck className="text-green-600 text-lg mr-2" />
                ) : (
                  <ImCross className="text-red-600 text-lg mr-2" />
                )}
                {dt.text}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

Pricing.propTypes = {
  data: PropTypes.array.isRequired,
  price: PropTypes.number.isRequired,
  duration: PropTypes.oneOf(["y", "m"]).isRequired,
  buttonContent: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
};

export default Pricing;
