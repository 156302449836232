import React, { useState } from 'react'
import MySwiper from '../mySwiper'
import axios from 'axios';
import { Base_url } from '../../utils/Base_url';

const Brands = () => {

   

  return (
    <div  className=' mb-16 bg-[#F7F8FD]'>
      <div className='  container   py-20 mx-auto'>
      <div className=' text-center'>
        <h1 className=" text-[#A36A2D] sm:text-5xl text-4xl font-Asap  font-semibold">
          
        Shop From Your Favorite Brand


        </h1>
        </div>
      <div className=' mt-20 w-[60%] mx-auto'>
      <MySwiper/>
      </div>
      </div>
    </div>
  )
}

export default Brands
