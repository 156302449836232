import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { Base_url } from "../../../utils/Base_url";
import { useSelector } from "react-redux";

const Register = () => {
  const { plan } = useSelector((state) => state.auth);
  const [loading,setLoader] = useState(false)
  console.log(plan);
  const navigate = useNavigate();
  const generateRandomAccountId = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };
  const formik = useFormik({
    initialValues: {
      country: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      city: "",
      state: "",
      address1: "",
      address2: "",
      zipCode: "",
      confirmPassword: "",
      whatsappUpdates: false,
      emailUpdates: false,
    },
    validationSchema: Yup.object().shape({
      country: Yup.string().required("Country is required"),
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      phone: Yup.string().required("Mobile Number is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      address1: Yup.string().required("Address 1  is required"),
      address2: Yup.string().required("Address2 is required"),
      zipCode: Yup.string().required("ZipCode  is required"),
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password should be at least 6 characters"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      updates: Yup.boolean().test(
        "is-true",
        "At least one checkbox must be selected",
        function () {
          return this.parent.whatsappUpdates || this.parent.emailUpdates;
        }
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      // Handle form submission
      const accountId = generateRandomAccountId();
      console.log(values);
      setSubmitting(false);
      setLoader(true);
      const parms = {
        planId: plan?._id,
        accountId: accountId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        password: values.password,
        country: values.country,
        city: values.city,
        state: values.state,
        address1: values.address1,
        address2: values.address2,
        zipCode: values.zipCode,
      };

      axios
        .post(`${Base_url}/user/create`, parms)
        .then((response) => {
          console.log(response);

          if (response.data.status === "ok") {
            console.log(response.data.data.email);

            axios
              .get(`${Base_url}/login/sendOtp/${response.data.data.email}`)
              .then((res) => {
                console.log(res);

                if (res.data.status === "ok") {
                  setLoader(false);
                  navigate(`/verify_screen/${response.data.data.email}`);
                  toast.success(res.data.message);
                } else {
                  setLoader(false);

                  toast.error(res.data.message);
                }
              })
              .catch((err) => {});
          } else {
            setLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
          toast(error.response.data.message);
          setLoader(false);

        });
    },
    validateOnBlur: true,
    validateOnChange: true,
  });

  const formRefs = {
    country: useRef(),
    firstName: useRef(),
    lastName: useRef(),
    email: useRef(),
    mobileNumber: useRef(),
    password: useRef(),
    confirmPassword: useRef(),
    city: useRef(),
    state: useRef(),
    address1: useRef(),
    address2: useRef(),
    zipCode: useRef(),
  };

  const scrollToError = (errors) => {
    for (const field in errors) {
      if (formRefs[field] && formRefs[field].current) {
        formRefs[field].current.scrollIntoView({ behavior: "smooth" });
        break;
      }
    }
  };

  return (
    <div
      className="min-h-screen py-40"
      style={{ backgroundImage: "linear-gradient(115deg, #F7F8FD, #F7F8FD)" }}
    >
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row w-10/12 lg:w-9/12 bg-white rounded-xl mx-auto shadow-lg overflow-hidden">
          <div className="w-full lg:w-1/2 py-14 px-12">
            <h2 className="text-3xl font-semibold mb-3">
              Signup To DiecWorld{" "}
            </h2>
            <p className="mb-4">Sign Up now become a member</p>
            <form
              onSubmit={(e) => {
                formik.handleSubmit(e);
                scrollToError(formik.errors);
              }}
            >
              <div className="mt-5">
                <select
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  ref={formRefs.country}
                  className={`border shadow-md text-gray-400 border-gray-300 py-2.5 rounded-lg px-2 w-full ${
                    formik.touched.country && formik.errors.country
                      ? "border-red-500"
                      : ""
                  }`}
                >
                  <option value="">Select Country</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="India">India</option>
                  <option value="China">China</option>
                  {/* Add country options here */}
                </select>
                {formik.touched.country && formik.errors.country ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.country}
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  ref={formRefs.firstName}
                  className={`border shadow-md border-gray-300 py-2.5 rounded-lg px-2 w-full ${
                    formik.touched.firstName && formik.errors.firstName
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.firstName}
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  ref={formRefs.lastName}
                  className={`border shadow-md border-gray-300 py-2.5 rounded-lg px-2 w-full ${
                    formik.touched.lastName && formik.errors.lastName
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.lastName}
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  ref={formRefs.email}
                  className={`border shadow-md border-gray-300 py-2.5 rounded-lg px-2 w-full ${
                    formik.touched.email && formik.errors.email
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <input
                  type="text"
                  name="phone"
                  placeholder="Mobile Number"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  ref={formRefs.phone}
                  className={`border shadow-md border-gray-300 py-2.5 rounded-lg px-2 w-full ${
                    formik.touched.phone && formik.errors.phone
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.phone}
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  ref={formRefs.password}
                  className={`border shadow-md border-gray-300 py-2.5 rounded-lg px-2 w-full ${
                    formik.touched.password && formik.errors.password
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  ref={formRefs.confirmPassword}
                  className={`border shadow-md border-gray-300 py-2.5 rounded-lg px-2 w-full ${
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.confirmPassword}
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <input
                  type="text"
                  name="address1"
                  placeholder="Address line 1"
                  value={formik.values.address1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  ref={formRefs.address1}
                  className={`border shadow-md border-gray-300 py-2.5 rounded-lg px-2 w-full ${
                    formik.touched.address1 &&
                    formik.errors.address1
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.address1 &&
                formik.errors.address1 ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.address1}
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <input
                  type="text"
                  name="address2"
                  placeholder="Address line 2"
                  value={formik.values.address2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  ref={formRefs.address2}
                  className={`border shadow-md border-gray-300 py-2.5 rounded-lg px-2 w-full ${
                    formik.touched.address2 &&
                    formik.errors.address2
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.address2 &&
                formik.errors.address2 ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.address2}
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  ref={formRefs.city}
                  className={`border shadow-md border-gray-300 py-2.5 rounded-lg px-2 w-full ${
                    formik.touched.city &&
                    formik.errors.city
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.city &&
                formik.errors.city ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.city}
                  </div>
                ) : null}
              </div>
              <div className=" grid  gap-4 grid-cols-2">
                <div className="mt-5">
                  <input
                    type="text"
                    name="state"
                    placeholder="State/Province"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    ref={formRefs.state}
                    className={`border shadow-md border-gray-300 py-2.5 rounded-lg px-2 w-full ${
                      formik.touched.state &&
                      formik.errors.state
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  {formik.touched.state &&
                  formik.errors.state ? (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.state}
                    </div>
                  ) : null}
                </div>
                <div className="mt-5">
                  <input
                    type="text"
                    name="zipCode"
                    placeholder="Zip Code"
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    ref={formRefs.zipCode}
                    className={`border shadow-md border-gray-300 py-2.5 rounded-lg px-2 w-full ${
                      formik.touched.zipCode &&
                      formik.errors.zipCode
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  {formik.touched.zipCode &&
                  formik.errors.zipCode ? (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.zipCode}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mt-5 flex flex-col">
                <div className="flex gap-2 items-center">
                  <input
                    type="checkbox"
                    name="whatsappUpdates"
                    checked={formik.values.whatsappUpdates}
                    onChange={formik.handleChange}
                    className="border w-4 h-4 border-gray-400"
                  />
                  <span className="text-black text-sm">
                    I'd like to receive updates on WhatsApp
                  </span>
                </div>
                <div className="flex gap-2 pt-2 items-center">
                  <input
                    type="checkbox"
                    name="emailUpdates"
                    checked={formik.values.emailUpdates}
                    onChange={formik.handleChange}
                    className="border w-4 h-4 border-gray-400"
                  />
                  <span className="text-black text-sm">
                    I'd like to receive marketing e-mails and updates
                  </span>
                </div>
                {formik.errors.updates && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.updates}
                  </div>
                )}
              </div>
              <div className="mt-5">
               
                {loading === true ? (
                <button
                  disabled
                  type="button"
                  class="w-full bg-[#A36A2D] rounded-md py-3 text-center text-white"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="inline w-4 h-4 me-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Loading...
                </button>
              ) : (
                <button
                type="submit"
                className="w-full bg-[#A36A2D] rounded-md py-3 text-center text-white"
              >
               Register Now
              </button>
              )}
              </div>
              <div className="mt-5 flex justify-center font-medium">
                <p>
                  Already have an account?{" "}
                  <Link to="/sign_In" className="text-[#A36A2D] font-semibold">
                    Login
                  </Link>
                </p>
              </div>
            </form>
          </div>
          <div
            className="w-full lg:w-1/2 flex flex-col items-center justify-center p-12 bg-no-repeat bg-cover bg-center"
            style={{
              backgroundImage: `url(${require("../../../assets/images/Register-Background.png")})`,
            }}
          >
            <h1 className="text-white text-3xl mb-3">Join us today!</h1>
            <div>
              <p className="text-white">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                suspendisse aliquam varius rutrum purus maecenas ac{" "}
                <a href="#" className="text-[#A36A2D] font-semibold">
                  Learn more
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
