import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import Brand1 from '../../assets/images/brand1.svg'
import Brand2 from '../../assets/images/brand2.svg'
import Brand3 from '../../assets/images/brand3.svg'
import Brand4 from '../../assets/images/brand4.svg'
import axios from 'axios';
import { Base_url } from '../../utils/Base_url';
const MySwiper = () => {

  const [brands,setBrands] = useState([]);


 useEffect(()=>{
  axios.get(`${Base_url}/brand/get`).then((res)=>{
      
    console.log(res);

    setBrands(res.data.data);
    
    
  }).catch((error)=>{

  })
 },[])
 

  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={4}
      loop={true}
      autoplay={{ delay: 2500, disableOnInteraction: false }}
      pagination={{ clickable: true }}
      modules={[Autoplay, Pagination]}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1280: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      }}
      className="brand-slider"
    >

      {brands?.map((item,index)=>{
        return (
<SwiperSlide>
        <div className=''>
          <img src={item?.image} alt="Slide 1" className="slide-image" />
        </div>
      </SwiperSlide>
        )
      })}
      
      
      
    </Swiper>
  );
};

export default MySwiper;
