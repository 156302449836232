import React from "react";
import { Link } from "react-router-dom";
import WareHouseLocation from "../../components/warehouseLocation";

const BuyItForMe = () => {
  return (
    <div>
      <div className=" bg-AboutUs bg-center  bg-cover w-full  flex  items-center h-[40vh]">
        <div className=" container sm:px-0 px-4 mx-auto">
          <h1 className="  text-white font-semibold uppercase text-3xl">
            Buy it for me
          </h1>
          <div className=" flex gap-2">
            <Link to={""} className="  text-[#A36A2D] font-semibold">
              Home
            </Link>
            <p className=" m-0  text-gray-400">/ Buy it for me</p>
          </div>
        </div>
      </div>

      <div className="">
        <div className="  container py-14 mx-auto">
          <h1 className=" text-center text-[#A36A2D] text-4xl font-bold">
            How does "Buy it for me" service works
          </h1>

          <div className="  flex  sm:px-0 px-4   gap-10 py-12 items-center flex-wrap  ">
            <div className=" sm:w-[30%] w-[70%]">
              <div className=" relative">
                <img
                  src={require("../../assets/images/step_arrow.png")}
                  className=" w-52"
                  alt=""
                />
                <span className=" absolute top-3 left-6 font-Asap text-3xl font-semibold text-white">
                  Step 01
                </span>
              </div>
              <p className=" pt-4 text-black font-semibold text-xl">
                Log in to your account and click on (Submit Request).
              </p>
            </div>
            <div className=" sm:w-[65%] w-[100%]">
              <img
                src={require("../../assets/images/buy_for1.gif")}
                className=" w-full"
                alt=""
              />
            </div>

            <div className=" sm:w-[65%] w-[100%]">
              <img
                src={require("../../assets/images/buy2.png")}
                className=" w-full"
                alt=""
              />
            </div>
            <div className=" sm:w-[30%] w-[70%]">
              <div className=" relative">
                <img
                  src={require("../../assets/images/step_arrow.png")}
                  className=" w-52"
                  alt=""
                />
                <span className=" absolute top-3 left-6 font-Asap text-3xl font-semibold text-white">
                  Step 02
                </span>
              </div>
              <p className=" pt-4 text-black font-semibold text-xl">
                Copy the product URL, paste the URL and fill the form with the
                required product details.
              </p>
            </div>

            <div className="sm:w-[30%] w-[70%]">
              <div className=" relative">
                <img
                  src={require("../../assets/images/step_arrow.png")}
                  className=" w-52"
                  alt=""
                />
                <span className=" absolute top-3 left-6 font-Asap text-3xl font-semibold text-white">
                  Step 03
                </span>
              </div>
              <p className=" pt-4 text-black font-semibold text-xl">
                Proceed with the payment for your order
              </p>
            </div>
            <div className=" sm:w-[65%] w-[100%]">
              <img
                src={require("../../assets/images/buy3.png")}
                className=" w-full"
                alt=""
              />
            </div>

            <div className=" sm:w-[65%] w-[100%]">
              <img
                src={require("../../assets/images/buy4.png")}
                className=" w-full"
                alt=""
              />
            </div>
            <div className=" sm:w-[30%] w-[70%]">
              <div className=" relative">
                <img
                  src={require("../../assets/images/step_arrow.png")}
                  className=" w-52"
                  alt=""
                />
                <span className=" absolute top-3 left-6 font-Asap text-3xl font-semibold text-white">
                  Step 04
                </span>
              </div>
              <p className=" pt-4 text-black font-semibold text-xl">
                We will receive your order, evaluate and process accordingly.
              </p>
            </div>

            <div className=" sm:w-[30%] w-[70%]">
              <div className=" relative">
                <img
                  src={require("../../assets/images/step_arrow.png")}
                  className=" w-52"
                  alt=""
                />
                <span className=" absolute top-3 left-6 font-Asap text-3xl font-semibold text-white">
                  Step 05
                </span>
              </div>
              <p className=" pt-4 text-black font-semibold text-xl">
                Once your product is purchased you can manage and ship through
                your main dashboard.
              </p>
            </div>
            <div className=" sm:w-[65%] w-[100%]">
              <img
                src={require("../../assets/images/buy5.png")}
                className=" w-full"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <WareHouseLocation />
    </div>
  );
};

export default BuyItForMe;
