import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WareHouseLocation from "../../components/warehouseLocation";
import { Base_url } from "../../utils/Base_url";
import axios from "axios";

const PurchaseRequest = () => {
  const user = JSON.parse(localStorage.getItem("diec_user_id"));

  const [users, setUsers] = useState([]);
  useEffect(() => {
    axios
      .get(`${Base_url}/order/getUser/${user?._id}`)
      .then((res) => {
        console.log(res.data);

        setUsers(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="bg-[#EEF1F5]">
      <div className=" bg-Track bg-center  bg-cover w-full   flex  items-center h-[40vh]">
        <div className=" container mx-auto sm:px-0 px-4">
          <h1 className="  text-white font-semibold text-3xl  uppercase">
            Smart Shopping, Made Easy
          </h1>
          <div className=" flex gap-2">
            <Link to={""} className="  text-[#A36A2D] font-semibold">
              Home
            </Link>
            <p className=" m-0  text-gray-400">/ Traking</p>
          </div>
        </div>
      </div>
      <div className=" ">
        <div className=" container w-[80%] mx-auto bg-white">
          <div className=" p-5 my-10">
            <div className="  flex justify-between items-center">
              <div className="gap-5 flex tw-items-center">
                <div>
                  <button className=" flex  gap-2 rounded-md text-black border px-3.5 py-1.5 font-medium">
                    All Requests
                    <p className=" bg-gray-600 w-6 h-6 m-0 flex justify-center items-center rounded-full">
                      <span className=" text-white text-sm">0</span>
                    </p>
                  </button>
                </div>
                <div>
                  <button className=" flex  gap-2 rounded-md text-black border px-3.5 py-1.5 font-medium">
                    Payment Pending
                    <p className=" bg-[#FF7F5C] w-6 h-6 m-0 flex justify-center items-center rounded-full">
                      <span className=" text-white text-sm">3</span>
                    </p>
                  </button>
                </div>
                <div>
                  <button className=" rounded-md flex gap-2 text-black border px-3.5 py-1.5 font-medium">
                    Completed
                    <p className=" bg-[#EE2E24] w-6 h-6 m-0 flex justify-center items-center rounded-full">
                      <span className=" text-white text-sm">3</span>
                    </p>
                  </button>
                </div>
              </div>
              <div>
                <button className="px-6  mx-auto mt-3 text-center text-sm bg-[#A36A2D]  hover:text-white border-2 text-white font-bold border-[#A36A2D] uppercase rounded-md py-2 flex items-center justify-center gap-1">
                  New Purchase Request
                </button>
              </div>
            </div>

            <div className="  grid  mt-4 gap-4 items-center grid-cols-4">
              <div className=" mt-2  ">
                <input
                  placeholder="Type your Request ID"
                  className=" w-full border rounded-md p-2 bg-white "
                  type="text"
                />
              </div>
              <div className=" mt-2">
                <input
                  className=" w-full border rounded-md p-2 bg-white "
                  type="date"
                />
              </div>
              <div className=" mt-2">
                <input
                  className=" w-full border rounded-md  p-2 bg-white "
                  type="date"
                />
              </div>
              <div>
                <button className="px-6  w-full mx-auto mt-3 text-center text-sm hover:bg-[#A36A2D] hover:text-white border-2 text-[#A36A2D] font-bold border-[#A36A2D] uppercase rounded-md py-2 flex items-center justify-center gap-1">
                  Search
                </button>
              </div>
            </div>

            <section className="mb-20 mt-7 text-gray-800">
              <div className="block rounded-lg">
                <div className="flex flex-col">
                  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="overflow-hidden">
                        <table className="min-w-full mb-0">
                          <thead className=" bg-[#F4F6F9]">
                            <tr className=" rounded-lg whitespace-nowrap ">
                              <th
                                scope="col"
                                className=" text-sm text-black  font-bold px-6 py-4"
                              >
                                Request ID
                              </th>
                              <th
                                scope="col"
                                className=" text-sm text-black  font-bold px-6 py-4"
                              >
                                Request Sent
                              </th>
                              <th
                                scope="col"
                                className=" text-sm text-black  font-bold px-6 py-4"
                              >
                                Request Detail
                              </th>

                              <th
                                scope="col"
                                className="text-sm  text-black   font-bold px-6 py-4"
                              >
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {users?.map((item, index) => {
                              return (
                                <tr className="bg-white border-t   rounded-md ">
                                  <th
                                    scope="row"
                                    className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                                  >
                                    <p className="mb-0.5 font-medium text-black">
                                      {item?._id}
                                    </p>
                                  </th>
                                  <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                    <span className=" text-base font-medium text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline     rounded-full">
                                      {item?.createdAt}
                                    </span>
                                  </td>
                                  <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                    <span className=" text-base font-medium text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline    rounded-full">
                                      {item?.comment}
                                    </span>
                                  </td>
                                  <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                    <span className=" text-base font-medium text-black  py-1 px-2.5 leading-none  whitespace-nowrap    rounded-full">
                                      Payment Pending
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <WareHouseLocation />
    </div>
  );
};

export default PurchaseRequest;
