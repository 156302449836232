
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './screens/home';
import Header from './components/header/Header';
import Footer from './components/footer';
import ColleagueBox from './components/colleagueBox';
import PlansScreen from './screens/PlansScreen';
import ContactUs from './screens/contactUs';
import SignIn from './screens/auth/signin';
import Register from './screens/auth/signup';
import AboutUs from './screens/aboutUs';
import TrackShipment from './screens/trackShipment';
import Chatbot from './components/chatbot';
import OurServices from './screens/ourServices';
import WhatsappChat from './components/what\'sApp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ItemConsolidation from './screens/ItemConsolidation';
import BuyItForMe from './screens/BuyItForMe';
import GoTop from './components/GoTop';
import PublicRoute from './routes/PublicRoute';
import VerifyScreen from './screens/auth/verifyScreen';
import MyDashboard from './screens/myDashboard/MyDashboard';
import MyAccount from './screens/myAccount';
import AccountAddressBook from './screens/myAccount/account-address-book/AccountAddressBook';
import PurchaseRequest from './screens/purchaseRequest';
function App() {
  return (
    <div className="App">
   <ColleagueBox/>
   <WhatsappChat/>
   <ToastContainer/>
   <Chatbot/>
     <Header/>
     <GoTop/>
     <Routes>
      <Route  path='/'  element={<Home/>} />
      <Route  path='/plans_screen'  element={<PlansScreen/>} />
      <Route  path='/contact_us'  element={<ContactUs/>} />
      <Route  path='/about_us'  element={<AboutUs/>} />
      <Route  path='/sign_In'  element={<PublicRoute><SignIn/></PublicRoute>} />
      <Route  path='/track_shipment'  element={<TrackShipment/>} />
      <Route  path='/register'  element={<PublicRoute><Register/></PublicRoute>} />
      <Route  path='/our_services'  element={<OurServices/>} />
      <Route  path='/item_consolidation'  element={<ItemConsolidation/>} />
      <Route  path='/BuyItForMe'  element={<BuyItForMe/>} />
      <Route  path='/My_dashboard'  element={<MyDashboard/>} />
      <Route  path='/Purchase_request'  element={<PurchaseRequest/>} />
      <Route  path='/My_account'  element={<MyAccount/>} />
      <Route  path='/account_address_book'  element={<AccountAddressBook/>} />
      <Route  path='/verify_screen/:id'  element={<VerifyScreen/>} />
     </Routes>
     <Footer/>
    </div>
  );
}

export default App;
