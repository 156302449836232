import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { MdMenu, MdOutlineClose } from "react-icons/md";
import { motion } from "framer-motion"; // Import Framer Motion
import TopNav from "./TopNav";
import { FaAngleDown } from "react-icons/fa6";

const Header = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (route) => location.pathname.includes(route);

  const handleNavigate = (path, sectionId) => {
    navigate(path);
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  const [isOpen2, setIsOpen2] = useState(false);

  const toggleMenu2 = () => {
    setIsOpen2(!isOpen2);
  };

  const user = JSON.parse(localStorage.getItem("diec_user_id"));


  const removeFun = () => {
    localStorage.removeItem("diec_user_id");
    navigate("/");
  };


  return (
    <>
      <TopNav />
      <nav className="bg-[#333537] top-0 z-20">
        <div className="container mx-auto md:px-0 px-4 flex items-center font-medium h-24 justify-between">
          <div>
            <img
              src={require("../../assets/images/logo2.png")}
              className="object-contain w-48"
              alt="Logo"
            />
          </div>

          {/* Desktop navigation */}
          <ul className="lg:flex hidden items-center gap-8">
            <li>
              <Link
                className={`${
                  isActive("/") ? "text-[#A36A2D]" : "text-white"
                } uppercase text-sm`}
                to={"/"}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to={"/"}
                onClick={() => handleNavigate("/", "howSection")}
                className="text-white  hover:text-[#A36A2D] uppercase text-sm"
              >
                HOW IT WORKS
              </Link>
            </li>
            <li>
              <Link
                to={"/"}
                onClick={() => handleNavigate("/", "aboutSection")}
                className="text-white hover:text-[#A36A2D] uppercase text-sm"
              >
                Shipping Addresses
              </Link>
            </li>
            <li>
              <Link
                to={"/track_shipment"}
                onClick={() => handleNavigate("shipmentSection")}
                className="text-white hover:text-[#A36A2D] uppercase text-sm"
              >
                TRACK SHIPMENT
              </Link>
            </li>
            <li>
              <div className="relative inline-block text-left">
                <div className="relative">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center gap-x-1.5 rounded-md text-white hover:text-[#A36A2D] px-3 py-2 text-sm font-semibold shadow-sm"
                    id="menu-button"
                    aria-expanded={isOpen}
                    aria-haspopup="true"
                    onClick={toggleMenu}
                  >
                    SERVICES
                    <svg
                      className="-mr-1 h-5 w-5 text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  {isOpen && (
                    <motion.div
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                      className="absolute right-0 z-40 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabIndex="-1"
                    >
                      <div className="py-1" role="none">
                        <Link
                          onClick={() => setIsOpen(false)}
                          to="/plans_screen"
                          className="block px-4 py-2 text-sm text-gray-700 hover:text-[#A36A2D]"
                          role="menuitem"
                          tabIndex="-1"
                          id="menu-item-0"
                        >
                          Package Consolidation
                        </Link>
                        <hr />
                        <Link
                          onClick={() => setIsOpen(false)}
                          to="/item_consolidation"
                          className="block px-4 py-2 text-sm text-gray-700 hover:text-[#A36A2D]"
                          role="menuitem"
                          tabIndex="-1"
                          id="menu-item-1"
                        >
                          Item Consolidation
                        </Link>
                        <hr />
                        <Link
                          onClick={() => setIsOpen(false)}
                          to="/our_services"
                          className="block px-4 py-2 text-sm text-gray-700 hover:text-[#A36A2D]"
                          role="menuitem"
                          tabIndex="-1"
                          id="menu-item-2"
                        >
                          Other Services
                        </Link>
                        <hr />
                        <Link
                          onClick={() => setIsOpen(false)}
                          to="/BuyItForMe"
                          className="block px-4 py-2 text-sm text-gray-700 hover:text-[#A36A2D]"
                          role="menuitem"
                          tabIndex="-1"
                          id="menu-item-2"
                        >
                          Buy it for me
                        </Link>
                      </div>
                    </motion.div>
                  )}
                </div>
              </div>
            </li>
            <li>
              <Link
                to={"/about_us"}
                onClick={() => handleNavigate("/", "roadSection")}
                className="text-white hover:text-[#A36A2D] text-sm"
              >
                ABOUT US
              </Link>
            </li>
          </ul>

          {/* Mobile menu toggle */}
          <div className="md:block hidden">
            {user ? (
              <div id="menu-button"
              aria-expanded={isOpen2}
              aria-haspopup="true"
              onClick={toggleMenu2} className=" flex items-center gap-3 relative">
                <h3 className="  text-white">
                  {" "}
                  {`${user?.firstName} ${"  "} ${user?.lastName}`}{" "}
                </h3>
                {/* <p className=" text-white text-[13px]">{user?.email}  </p> */}

                <FaAngleDown color="#A36A2D" />

                {isOpen2 && (
                    <motion.div
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                      className="absolute right-0 z-40 mt-32 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabIndex="-1"
                    >
                      <div className="py-1" role="none">
                        <Link
                          onClick={() => setIsOpen(false)}
                          to="/My_dashboard"
                          className="block px-4 py-2 text-sm text-gray-700 hover:text-[#A36A2D]"
                          role="menuitem"
                          tabIndex="-1"
                          id="menu-item-0"
                        >
                          Dashboard
                        </Link>
                        <hr />
                        <Link
                          onClick={() => setIsOpen(false)}
                          to="/My_account"
                          className="block px-4 py-2 text-sm text-gray-700 hover:text-[#A36A2D]"
                          role="menuitem"
                          tabIndex="-1"
                          id="menu-item-0"
                        >
                          Account
                        </Link>
                        <hr/>
                        <Link
                          onClick={() => removeFun()}
                          to="/"
                          className="block px-4 py-2 text-sm text-gray-700 hover:text-[#A36A2D]"
                          role="menuitem"
                          tabIndex="-1"
                          id="menu-item-1"
                        >
                          Logout
                        </Link>
                        
                      </div>
                    </motion.div>
                  )}

              </div>
            ) : (
              <div className="flex gap-4">
                <Link to={"/plans_screen"}>
                  <button className="px-6 text-center text-sm hover:bg-[#A36A2D] hover:text-white border-2 text-[#fff] border-[#A36A2D] uppercase rounded-sm py-2 flex items-center justify-center gap-1">
                    Sign Up
                  </button>
                </Link>
                <Link to={"/plans_screen"}>
                  <button className="px-6 text-center text-sm uppercase rounded-sm py-2.5 flex items-center justify-center gap-1 bg-[#A36A2D] text-white">
                    Sign In
                  </button>
                </Link>
              </div>
            )}
          </div>

          {/* Mobile menu icon */}
          <div className="text-3xl lg:hidden " onClick={() => setOpen(!open)}>
            <MdMenu color="white" />
          </div>

          {/* Mobile navigation */}
          <div
            className={`
              lg:hidden bg-[#262829] bg-cover z-[9999]  fixed w-full top-0 overflow-y-auto bottom-0 leading-10 py-10 
              duration-500 ${open ? "left-0" : "left-[-100%]"}
            `}
          >
            <div className=" flex  px-4 items-center justify-between">
              <div className="pb-5">
                <img
                  src={require("../../assets/images/logo2.png")}
                  className="object-contain sm:w-auto w-48"
                  alt="Logo"
                />
              </div>

              <div onClick={() => setOpen(!open)}>
                <MdOutlineClose size={25} color="white" />
              </div>
            </div>

            <ul className="p-0 leading-[45px] relative px-9 pt-3 border-t">
              <li onClick={() => setOpen(false)}>
                <Link
                  className={`${
                    isActive("/") ? "text-[#A36A2D]" : "text-white"
                  } uppercase text-sm`}
                  to={"/"}
                >
                  Home
                </Link>
              </li>
              <li onClick={() => setOpen(false)}>
                <Link
                  to={"/"}
                  onClick={() => handleNavigate("/", "howSection")}
                  className="text-white  hover:text-[#A36A2D] uppercase text-sm"
                >
                  HOW IT WORKS
                </Link>
              </li>
              <li onClick={() => setOpen(false)}>
                <Link
                  to={"/"}
                  onClick={() => handleNavigate("/", "aboutSection")}
                  className="text-white hover:text-[#A36A2D] uppercase text-sm"
                >
                  Shipping Addresses
                </Link>
              </li>
              <li onClick={() => setOpen(false)}>
                <Link
                  to={"/track_shipment"}
                  onClick={() => handleNavigate("shipmentSection")}
                  className="text-white hover:text-[#A36A2D] uppercase text-sm"
                >
                  TRACK SHIPMENT
                </Link>
              </li>
              <li>
                <div className="relative inline-block text-left">
                  <div className="relative">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center gap-x-1.5 rounded-md text-white hover:text-[#A36A2D]  py-2 text-sm font-semibold shadow-sm"
                      id="menu-button"
                      aria-expanded={isOpen}
                      aria-haspopup="true"
                      onClick={toggleMenu}
                    >
                      SERVICES
                      <svg
                        className="-mr-1 h-5 w-5 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>

                    {isOpen && (
                      <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                        className="absolute left-0 z-40 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="menu-button"
                        tabIndex="-1"
                      >
                        <div
                          onClick={() => setOpen(false)}
                          className="py-1"
                          role="none"
                        >
                          <Link
                            onClick={() => setIsOpen(false)}
                            to="/plans_screen"
                            className="block px-4 py-2 text-sm text-gray-700 hover:text-[#A36A2D]"
                            role="menuitem"
                            tabIndex="-1"
                            id="menu-item-0"
                          >
                            Package Consolidation
                          </Link>
                          <hr />
                          <Link
                            onClick={() => setIsOpen(false)}
                            to="/item_consolidation"
                            className="block px-4 py-2 text-sm text-gray-700 hover:text-[#A36A2D]"
                            role="menuitem"
                            tabIndex="-1"
                            id="menu-item-1"
                          >
                            Item Consolidation
                          </Link>
                          <hr />
                          <Link
                            onClick={() => setIsOpen(false)}
                            to="/our_services"
                            className="block px-4 py-2 text-sm text-gray-700 hover:text-[#A36A2D]"
                            role="menuitem"
                            tabIndex="-1"
                            id="menu-item-2"
                          >
                            Other Services
                          </Link>
                          <hr />
                          <Link
                            onClick={() => setIsOpen(false)}
                            to="/BuyItForMe"
                            className="block px-4 py-2 text-sm text-gray-700 hover:text-[#A36A2D]"
                            role="menuitem"
                            tabIndex="-1"
                            id="menu-item-2"
                          >
                            Buy it for me
                          </Link>
                        </div>
                      </motion.div>
                    )}
                  </div>
                </div>
              </li>
              <li onClick={() => setOpen(false)}>
                <Link
                  to={"/about_us"}
                  onClick={() => handleNavigate("/", "roadSection")}
                  className="text-white hover:text-[#A36A2D] text-sm"
                >
                  ABOUT US
                </Link>
              </li>
            </ul>

            <div className="">
              <div className="flex flex-col px-6  justify-around pt-4 gap-4">
                <Link
                  onClick={() => setOpen(false)}
                  to={"/plans_screen"}
                  className=" w-full"
                >
                  <button className="px-6 text-center text-sm w-full hover:bg-[#A36A2D] hover:text-white border-2 text-[#fff] border-[#A36A2D] uppercase rounded-sm py-2 flex items-center justify-center gap-1">
                    Sign Up
                  </button>
                </Link>
                <Link onClick={() => setOpen(false)} to={"/sign_In"}>
                  <button className="px-6 text-center text-sm w-full uppercase rounded-sm py-2.5 flex items-center justify-center gap-1 bg-[#A36A2D] text-white">
                    Sign In
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
