import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import WareHouseLocation from '../../components/warehouseLocation'
import TrackShipmentComponent from '../../components/TrackShipment'
import { Base_url } from '../../utils/Base_url'
import axios from 'axios'

const TrackShipment = () => {


  
  return (
    <>
    <div className=' bg-Track bg-center  bg-cover w-full  flex  items-center h-[40vh]'>
       <div className=' container mx-auto sm:px-0 px-4'>
       <h1  className='  text-white font-semibold text-3xl'>TRACKING</h1>
       <div className=' flex gap-2'>
        <Link to={''} className='  text-[#A36A2D] font-semibold'>Home</Link>
        <p className=' m-0  text-gray-400'>/ Traking</p>
       </div>
       </div>
       </div>
   
   <div >
   <TrackShipmentComponent/>
   </div>

   <WareHouseLocation/>
    </>
  )
}

export default TrackShipment
