import React, { useState, useRef, useEffect } from 'react';

const Chatbot = () => {
  const [isChatbotVisible, setIsChatbotVisible] = useState(false);
  const [chatMessages, setChatMessages] = useState([
    {
      type: 'incoming',
      message: 'Hi there 👋\nHow can I help you today?',
    },
  ]);
  const [userMessage, setUserMessage] = useState('');
  const chatboxRef = useRef(null);
  const chatInputRef = useRef(null);

  const API_KEY = 'PASTE-YOUR-API-KEY';

  useEffect(() => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollTo(0, chatboxRef.current.scrollHeight);
    }
  }, [chatMessages]);

  const handleChat = () => {
    const message = userMessage.trim();
    if (!message) return;

    setChatMessages([...chatMessages, { type: 'outgoing', message }]);
    setUserMessage('');

    setTimeout(() => {
      const incomingMessage = 'Thinking...';
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { type: 'incoming', message: incomingMessage },
      ]);
      generateResponse(message);
    }, 600);
  };

  const generateResponse = (userMessage) => {
    const API_URL = 'https://api.openai.com/v1/chat/completions';

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${API_KEY}`,
      },
      body: JSON.stringify({
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: userMessage }],
      }),
    };

    fetch(API_URL, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        const responseMessage = data.choices[0].message.content.trim();
        setChatMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1].message = responseMessage;
          return updatedMessages;
        });
      })
      .catch(() => {
        setChatMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1].message =
            'Oops! Something went wrong. Please try again.';
          updatedMessages[updatedMessages.length - 1].type = 'error';
          return updatedMessages;
        });
      });
  };

  return (
    <div>
      <button
        className={`fixed bottom-5 z-50 right-5 h-14 w-14 flex items-center justify-center rounded-full bg-[#A36A2D] transition-transform duration-200 ${
          isChatbotVisible ? 'rotate-90' : ''
        }`}
        onClick={() => setIsChatbotVisible(!isChatbotVisible)}
      >

        {!isChatbotVisible? <span className="material-symbols-rounded text-white">
          mode_comment
        </span>: <span className="material-symbols-outlined text-white">
          close
        </span>}
       
       
      </button>
      {isChatbotVisible && (
        <div className="fixed right-8 z-50 bottom-24 w-96 bg-white rounded-xl shadow-lg transition-transform duration-100 transform scale-95 origin-bottom-right">
          <header className="py-4 relative text-center bg-[#A36A2D] text-white shadow">
            <h2 className="text-lg">Chatbot</h2>
            <span
              className="material-symbols-outlined absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
              onClick={() => setIsChatbotVisible(false)}
            >
              close
            </span>
          </header>
          <ul className="chatbox overflow-y-auto h-[300px] p-6 pb-24">
            {chatMessages.map((msg, index) => (
              <li
                key={index}
                className={`chat flex ${
                  msg.type === 'outgoing' ? 'justify-end my-4' : 'my-4'
                }`}
              >
                {msg.type === 'incoming' && (
                  <span className="material-symbols-outlined w-8 h-8 bg-[#A36A2D] text-white flex items-center justify-center rounded mr-2">
                    smart_toy
                  </span>
                )}
                <p
                  className={`p-3 rounded-lg max-w-[75%] ${
                    msg.type === 'outgoing'
                      ? 'bg-indigo-600 text-white rounded-br-none'
                      : 'bg-gray-200 text-black rounded-bl-none'
                  } ${msg.type === 'error' ? 'bg-red-200 text-red-800' : ''}`}
                >
                  {msg.message}
                </p>
              </li>
            ))}
          </ul>
          <div className="chat-input flex gap-1 p-4 border-t border-gray-300">
            <textarea
              ref={chatInputRef}
              className="flex-1 h-12 p-3 resize-none border-none outline-none"
              placeholder="Enter a message..."
              spellCheck="false"
              required
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleChat();
                }
              }}
            ></textarea>
            <span
              id="send-btn"
              className="material-symbols-rounded text-[#A36A2D] cursor-pointer flex items-center"
              onClick={handleChat}
            >
              send
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
