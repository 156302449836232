import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { Base_url } from "../../../utils/Base_url";

const Signin = () => {
  const navigate = useNavigate();
  const [loading,setLoader] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: true,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
      rememberMe: Yup.boolean().oneOf(
        [true],
        "You must accept the remember me"
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      console.log(values);
      setLoader(true);
      setSubmitting(false);
      // toast.success("registration");

      const parms = {
        email: values.email,
        password: values.password,
      };

      axios
        .post(`${Base_url}/login/user`, parms)
        .then((res) => {
          console.log(res);

          if (res.data.status === "ok") {
            toast.success("User Login successfully!");
            setLoader(false);
            localStorage.setItem(
              "diec_user_id",
              JSON.stringify(res?.data?.data)
            );

            navigate("/");
          } else {
            toast.error(res.data.message);
            setLoader(false);

            if(res.data.message==="Your account is blocked. Please verify your account and try again."){
              navigate(`/verify_screen/${values.email}`)
            }
          }
        })
        .catch((error) => {
          console.log(error);
          toast(error.response.data.message);
          setLoader(false);

        });
    },
  });

  return (
    <div
      className="min-h-screen py-40"
      style={{ backgroundImage: "linear-gradient(115deg, #F7F8FD, #F7F8FD)" }}
    >
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row w-10/12 lg:w-8/12 bg-white rounded-xl mx-auto shadow-lg overflow-hidden">
          <div
            className="w-full lg:w-1/2 flex flex-col items-center justify-center p-12 bg-no-repeat bg-cover bg-center"
            style={{
              backgroundImage: `url(${require("../../../assets/images/Register-Background.png")})`,
            }}
          >
            <h1 className="text-white text-3xl mb-3">
              Welcome back to Diec World
            </h1>
            <div>
              <p className="text-white">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                suspendisse aliquam varius rutrum purus maecenas ac{" "}
                <a href="#" className="text-[#A36A2D] font-semibold">
                  Learn more
                </a>
              </p>
            </div>
          </div>
          <div className="w-full lg:w-1/2 py-14 px-12">
            <h2 className="text-3xl font-semibold mb-3">Login To DiecWorld</h2>
            <p className="mb-4">Login In to access your account</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="mt-5">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  className={`border shadow-md border-gray-300 py-2.5 rounded-lg px-2 w-full ${
                    formik.touched.email && formik.errors.email
                      ? "border-red-500"
                      : ""
                  }`}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  className={`border shadow-md border-gray-300 py-2.5 rounded-lg px-2 w-full ${
                    formik.touched.password && formik.errors.password
                      ? "border-red-500"
                      : ""
                  }`}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>
              <div className="mt-5 flex justify-between items-center">
                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    name="rememberMe"
                    className="border w-4 h-4 border-gray-400"
                    checked={formik.values.rememberMe}
                    onChange={formik.handleChange}
                  />
                  <span className="text-black text-sm">Remember Me</span>
                </div>

                <span>
                  <a href="#" className="text-[#A36A2D] font-semibold">
                    Forget Password?
                  </a>
                </span>
              </div>
              {formik.touched.rememberMe && formik.errors.rememberMe ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.rememberMe}
                </div>
              ) : null}

              <div className="mt-5">
                
                {loading === true ? (
                <button
                  disabled
                  type="button"
                  class="w-full bg-[#A36A2D] rounded-md py-3 text-center text-white"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="inline w-4 h-4 me-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Loading...
                </button>
              ) : (
                <button
                type="submit"
                className="w-full bg-[#A36A2D] rounded-md py-3 text-center text-white"
              >
               Login
              </button>
              )}
              </div>
              <div className="mt-5 flex justify-center font-medium">
                <p>
                  Not a member?{" "}
                  <Link
                    to={"/register"}
                    className="text-[#A36A2D] font-semibold"
                  >
                    Signup Here
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
