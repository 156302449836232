import React from 'react'

const WareHouseLocation = () => {
  return (
    <div  className='bg-[#F7F8FD]'> 

        <div className=' container p-12  mx-auto'>
        <div className=' text-center'>
        <h1 className=" text-[#A36A2D] sm:text-5xl text-4xl font-Asap  font-semibold">
          
          Our Warehouse Locations


        </h1>
        </div>


        <div className=' grid sm:grid-cols-3 gap-7 grid-cols-2 pt-12'>
         <div className=' flex  justify-center'>
         <div className=' w-56'>
                <img src='https://shopiniworld.com/static/media/US.db20d311.svg' className=' w-full' alt='' />
                <p className=' text-xl font-Asap text-center pt-4'>United States</p>
            </div>
         </div>
            <div className=' flex  justify-center'>
            <div className=' w-56'>
                <img src='https://shopiniworld.com/static/media/UAE.e3b5eb0b.svg' className=' w-full' alt='' />
                <p className=' text-xl font-Asap text-center pt-4'>UAE</p>
            </div>
            </div>
            <div className=' flex  justify-center'>
            <div className=' w-56'>
                <img src='https://shopiniworld.com/static/media/TR-en.5c51d1d0.svg' className=' w-full' alt='' />
                <p className=' text-xl font-Asap text-center pt-4'>Turkey</p>
            </div>
            </div>
        </div>
        </div>
      
    </div>
  )
}

export default WareHouseLocation
