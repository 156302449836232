import React from 'react'
import Hero from '../../components/hero'
import CalculatorFree from '../../components/calculatorFree'
import HowItsWorks from '../../components/howItsWorks'
import OurServices from '../../components/ourServices'
import WareHouseLocation from '../../components/warehouseLocation'
import Brands from '../../components/brands'
import MySwiper from '../../components/mySwiper'
import ImageSwiper from '../../components/ImageSwiper'
import HeroBottom from '../../components/heroBottom'
// import TrackShipment from '../../components/TrackShipment'


const Home = () => {
  return (
    <div>
       
       <ImageSwiper/>
       {/* <Hero/> */}
       {/* <MySwiper/> */}
       <HeroBottom />
       <HowItsWorks/>
       <Brands/>
       {/* <TrackShipment/> */}
       <CalculatorFree/>
     
       <OurServices/>
       
       <WareHouseLocation/>
    </div>
  )
}

export default Home
