import React from 'react'
import { BsInstagram } from 'react-icons/bs'
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className='bg-[#333537]'>
      <div className=''>
        <div className='container px-6 mx-auto grid gap-10 sm:grid-cols-5 grid-cols-1 py-9'>
          <div>
            <img src={require('../../assets/images/logo2.png')} className='w-48' alt="logo" />
            <p className='m-0 text-white pt-5 text-left text-sm'>
              The trade war currently ensuing between the US and several nations around the globe, most fiercely with.
            </p>
            <ul className='flex gap-4 p-0 pt-5'>
              <li>
                <Link to={'/'} className=''>
                  <p className='m-0 rounded-full flex justify-center items-center w-10 h-10 bg-white'>
                    <FaFacebookF color='#A46A2D' />
                  </p>
                </Link>
              </li>
              <li>
                <Link to={'/'}>
                  <p className='m-0 rounded-full flex justify-center items-center w-10 h-10 bg-white'>
                    <FaTwitter color='#A46A2D' />
                  </p>
                </Link>
              </li>
              <li>
                <Link to={'/'}>
                  <p className='m-0 rounded-full flex justify-center items-center w-10 h-10 bg-white'>
                    <BsInstagram color='#A46A2D' />
                  </p>
                </Link>
              </li>
              <li>
                <Link to={'/'}>
                  <p className='m-0 rounded-full flex justify-center items-center w-10 h-10 bg-white'>
                    <FaLinkedinIn color='#A46A2D' />
                  </p>
                </Link>
              </li>
            </ul>
          </div>

          <div className="">
            <h1 className='text-white font-semibold text-xl'>COMPANY</h1>
            <ul className='p-0 leading-9 pt-3'>
              <li>
                <Link className='text-white hover:text-[#A36A2D]' to={""}>Top Stores</Link>
              </li>
              <li>
                <Link className='text-white hover:text-[#A36A2D]' to={""}>Contact Us</Link>
              </li>
              <li>
                <Link className='text-white hover:text-[#A36A2D]' to={""}>Privacy Policies</Link>
              </li>
              <li>
                <Link className='text-white hover:text-[#A36A2D]' to={""}>Terms of Use</Link>
              </li>
            </ul>
          </div>

          <div className="">
            <h1 className='text-white font-semibold text-xl'>Help</h1>
            <ul className='leading-9 pt-3'>
              <li className='text-left'>
                <Link className='text-white hover:text-[#A36A2D]' to={""}>Join DIEC WORLD</Link>
              </li>
              <li>
                <Link className='text-white hover:text-[#A36A2D]' to={""}>Track Shipment</Link>
              </li>
              <li>
                <Link className='text-white hover:text-[#A36A2D]' to={""}>Prohibited Items</Link>
              </li>
              <li>
                <Link className='text-white hover:text-[#A36A2D]' to={""}>FAQs</Link>
              </li>
            </ul>
          </div>

          <div className="">
            <h1 className='text-white font-semibold text-xl'>About</h1>
            <ul className='leading-9 pt-3'>
              <li className='text-left'>
                <Link className='text-white hover:text-[#A36A2D]' to={"/plans_screen"}>Plans & Pricing</Link>
              </li>
              <li>
                <Link className='text-white hover:text-[#A36A2D]' to={"/plans_screen"}>How It Works</Link>
              </li>
              <li>
                <Link className='text-white hover:text-[#A36A2D]' to={""}>Why Choose Us</Link>
              </li>
              <li>
                <Link className='text-white hover:text-[#A36A2D]' to={"/contact_us"}>Contact Us</Link>
              </li>
              <li>
                <Link className='text-white hover:text-[#A36A2D]' to={""}>About Us</Link>
              </li>
            </ul>
          </div>

          <div className=''>
            <h1 className='text-white font-semibold text-xl'>NOW SHOP IT, SHIP IT ON THE GO</h1>
            <div className='flex gap-4 pt-4'>
              <div>
                <img src='https://shopiniworld.com/static/media/ios.8671d264.svg' alt='' />
              </div>
              <div>
                <img src='https://shopiniworld.com/static/media/android.9385089f.svg' alt='' />
              </div>
            </div>
          </div>
        </div>

        <footer className='second_footer bg-[#262829] py-3'>
          <p className='text-white m-0 text-center'>
            Shop & Ship is an online shipping delivery service from Diec world | Copyright ©2024 Diec world International LLC | All rights reserved
          </p>
        </footer>
      </div>
    </footer>
  )
}

export default Footer
