import React, { useEffect, useState } from "react";
import { LuCheckCircle } from "react-icons/lu";
import { Base_url } from "../../utils/Base_url";
import axios from "axios";
import { MdClose } from "react-icons/md";
import Modal from "../myModal/Modal";

const CalculatorFree = () => {
  const [formCountry, setFormCountry] = useState([]);
  const [toCountry, setToCountry] = useState([]);
  const [selectedFromCountry, setSelectedFromCountry] = useState("");
  const [selectedToCountry, setSelectedToCountry] = useState("");
  const [parcelWeight, setParcelWeight] = useState("");
  const [total, setTotal] = useState("");
  const [unit, setUnit] = useState("kg"); 
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    axios
      .get(`${Base_url}/calculate/getFromCountry`)
      .then((res) => {
        setFormCountry(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${Base_url}/calculate/getToCountry`)
      .then((res) => {
        setToCountry(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const submitData = (e) => {
    e.preventDefault();
    const params = {
      fromCountry: selectedFromCountry,
      toCountry: selectedToCountry,
      quantity: parcelWeight,
      unit: unit // Include unit in the params
    };
    axios
      .post(`${Base_url}/calculate/getPrice`, params)
      .then((res) => {
        console.log(res);
        setTotal(res.data.data); // Update the total input field
        // openModal()

        
      })
      .catch((error) => {
        console.log(error);
      });
  };






  return (
    <div>
      <div className="container px-6 sm:px-0 mx-auto py-16">
        <h1 className="text-[#A36A2D] sm:text-5xl text-4xl font-Asap font-semibold">
          GET FREE ESTIMATE
        </h1>
        <h2 className="text-black sm:text-5xl text-4xl font-Asap font-semibold">
          RIGHT NOW
        </h2>

        <div className="grid md:grid-cols-2 grid-cols-1 gap-12 pt-11">
          <div>
            <form onSubmit={submitData} className="flex flex-col gap-4">
              <div>
                <select
                  name="fromCountry"
                  className="bg-[#262829] w-full text-white p-3 rounded-sm text-sm"
                  value={selectedFromCountry}
                  onChange={(e) => setSelectedFromCountry(e.target.value)}
                >
                  <option>Ship from</option>
                  {formCountry.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <select
                  name="toCountry"
                  className="bg-[#262829] w-full text-white p-3 rounded-sm text-sm"
                  value={selectedToCountry}
                  onChange={(e) => setSelectedToCountry(e.target.value)}
                >
                  <option>Ship to</option>
                  {toCountry.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <input
                  name="parcelWeight"
                  placeholder="Parcel weight?"
                  className="placeholder:text-white bg-[#262829] outline-none p-3 w-full rounded-sm text-white"
                  value={parcelWeight}
                  onChange={(e) => setParcelWeight(e.target.value)}
                />
              </div>

              <div className="grid sm:grid-cols-3 grid-cols-2 gap-3">
                <div>
                  <select
                    className="bg-[#262829] w-full text-white p-3.5 rounded-sm text-sm"
                    value={unit} // Bind to state
                    onChange={(e) => setUnit(e.target.value)} // Update state on change
                  >
                    <option value="kg">kg</option>
                    <option value="pound">pound</option>
                  </select>
                </div>
                <div>
                  <input
                    disabled
                    placeholder="Total"
                    className="placeholder:text-white bg-[#262829] outline-none p-3 w-full rounded-sm text-white"
                    value={total}
                  />
                </div>
                <button
                  type="submit"
                  className="justify-center bg-[#A36A2D] text-white py-2 px-4 rounded-sm flex gap-2 items-center text-sm font-semibold"
                >
                  GET AN ESTIMATE <LuCheckCircle color="white" />
                </button>
              </div>
            </form>

            <div className="flex flex-col pt-8 gap-8">
              <div className="flex gap-4">
                <div>
                  <img
                    src={require("../../assets/images/cyber-security.png")}
                    width={60}
                    alt=""
                  />
                </div>
                <div>
                  <h3 className="text-black font-Asap text-xl font-semibold">
                    Cargo Security
                  </h3>
                  <p className="m-0 text-sm text-gray-500">
                    Our clients receive 100% guarantee for the most secure
                    shipping and handling.
                  </p>
                </div>
              </div>
              <div className="flex gap-4">
                <div>
                  <img
                    src={require("../../assets/images/cashless-payment.png")}
                    width={60}
                    alt=""
                  />
                </div>
                <div>
                  <h3 className="text-black font-Asap text-xl font-semibold">
                    Any Payment Method
                  </h3>
                  <p className="m-0 text-sm text-gray-500">
                    Any suitable payment methods are acceptable while ordering
                    service with us.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div className="flex gap-4">
              <div>
                <img
                  src={require("../../assets/images/worldwide.png")}
                  width={60}
                  alt=""
                />
              </div>
              <div>
                <h3 className="text-black font-Asap text-xl font-semibold">
                  Best Logistics
                </h3>
                <p className="m-0 text-sm text-gray-500">
                  We provide the best logistic service on the market, all over
                  the globe.
                </p>
              </div>
            </div>
            <div className="flex gap-4">
              <div>
                <img
                  src={require("../../assets/images/on-time.png")}
                  width={60}
                  alt=""
                />
              </div>
              <div>
                <h3 className="text-black font-Asap text-xl font-semibold">
                  On-Time Delivery
                </h3>
                <p className="m-0 text-sm text-gray-500">
                  We know how to make it in time and set the right terms for
                  deliveries.
                </p>
              </div>
            </div>
            <div className="flex gap-4">
              <div>
                <img
                  src={require("../../assets/images/24-hours-support.png")}
                  width={60}
                  alt=""
                />
              </div>
              <div>
                <h3 className="text-black font-Asap text-xl font-semibold">
                  24-Hour Support
                </h3>
                <p className="m-0 text-sm text-gray-500">
                  In case you have an enquiry, or an urgent question, our
                  support is there.
                </p>
              </div>
            </div>
            <div className="flex gap-4">
              <div>
                <img
                  src={require("../../assets/images/tracking.png")}
                  width={60}
                  alt=""
                />
              </div>
              <div>
                <h3 className="text-black font-Asap text-xl font-semibold">
                  Cargo Tracking
                </h3>
                <p className="m-0 text-sm text-gray-500">
                  Track all your shipments online using the simple form and the
                  track number.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>




      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        className={" md:w-[80%] h-screen rounded-lg w-[100%]"}
      >
        {/* Modal Content */}
        <div className="">
          <div className=" p-5 flex justify-between items-center">
         
            <h1 className="capitalize  text-black font-semibold text-xl h4">Estimated shipping Cost with Diec World</h1>
            <div></div>
            <MdClose
              onClick={() => setIsModalOpen(false)}
              size={25}
              color="black"
            />
          </div>
          <hr />
          <form  className=" p-5">



            <ul  className="  flex flex-col gap-12 p-0">
              <li className=" flex justify-between items-center">
                
                
                <span className=" font-semibold text-lg">{total} $</span>
              </li>
            </ul>
            
          </form>
        </div>
      </Modal>

    </div>
  );
};

export default CalculatorFree;
