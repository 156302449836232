import React, { useEffect, useState } from "react";
import WareHouseLocation from "../../components/warehouseLocation";
import buy1 from "../../assets/images/buyfor1.svg";
import buy2 from "../../assets/images/buyfor2.svg";
import BuyForModal from "./BuyForModal";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";
const MyDashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const user = JSON.parse(localStorage.getItem("diec_user_id"));

  const [users, setUsers] = useState([]);
  useEffect(() => {
    axios
      .get(`${Base_url}/book/getUser/${user?._id}`)
      .then((res) => {
        console.log(res.data);

        setUsers(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tabs, setAtiveTabs] = useState("all");

  const handleSearch = () => {
    const filtered = users.filter((user) => {
      const userDate = new Date(user.createdAt);
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;

      const isWithinDateRange =
        (!start || userDate >= start) && (!end || userDate <= end);
      const matchesRequestId = !requestId || user._id.includes(requestId);
      return isWithinDateRange && matchesRequestId;
    });
    setFilteredUsers(filtered);
  };

  return (
    <>
      <BuyForModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
      <div className=" bg-[#EEF1F5]">
        <div className=" container  py-12 mx-auto">
          <div className=" flex  gap-8 justify-between">
            <div className=" w-[65%]">
              <div className="  shadow-md p-4  flex items-center bg-white">
                <div>
                  <img src={buy1} alt="" />
                </div>
                <div className=" text-center ">
                  <h3 className=" text-black font-semibold text-lg">
                    Buy it for me
                  </h3>
                  <p className="  text-black pt-4 text-sm">
                    Tell us what you want to buy by pasting a link, We'll make
                    the purchase for you & deliver to your door worldwide!
                  </p>
                  <button
                    onClick={() => openModal()}
                    className="px-6  mx-auto mt-3 text-center text-sm hover:bg-[#A36A2D] hover:text-white border-2 text-[#A36A2D] font-bold border-[#A36A2D] uppercase rounded-md py-2 flex items-center justify-center gap-1"
                  >
                    Submit Request
                  </button>
                </div>
                <div>
                  <img src={buy2} alt="" />
                </div>
              </div>

              <div className=" bg-white mt-4 rounded-md p-4">
                <div>
                  <div className=" flex justify-between items-center">
                    <div className="gap-5 flex tw-items-center">
                      <div>
                        <button
                          onClick={() => {
                            setAtiveTabs("all");
                          }}
                          className={` ${
                            tabs === "all" ? " bg-gray-200" : ""
                          } flex  gap-2 rounded-md text-black border px-3.5 py-1.5 font-medium`}
                        >
                          All Requests
                          <p className=" bg-gray-600 w-6 h-6 m-0 flex justify-center items-center rounded-full">
                            <span className=" text-white text-sm">0</span>
                          </p>
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => {
                            setAtiveTabs("payment");
                          }}
                          className={` ${
                            tabs === "payment" ? " bg-gray-200" : ""
                          } flex  gap-2 rounded-md text-black border px-3.5 py-1.5 font-medium`}
                        >
                          Pending Payment
                          <p className=" bg-[#FF7F5C] w-6 h-6 m-0 flex justify-center items-center rounded-full">
                            <span className=" text-white text-sm">3</span>
                          </p>
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => {
                            setAtiveTabs("complete");
                          }}
                          className={` ${
                            tabs === "complete" ? " bg-gray-200" : ""
                          } flex  gap-2 rounded-md text-black border px-3.5 py-1.5 font-medium`}
                        >
                          Completed
                          <p className=" bg-[#EE2E24] w-6 h-6 m-0 flex justify-center items-center rounded-full">
                            <span className=" text-white text-sm">3</span>
                          </p>
                        </button>
                      </div>
                    </div>
                    <div className="">
                      <button
                        onClick={() => openModal()}
                        className="px-6   w-full text-center text-sm bg-[#A36A2D]  hover:text-white border-2 text-white font-bold border-[#A36A2D] uppercase rounded-md py-2 flex items-center justify-center gap-1"
                      >
                        New Purchase Request
                      </button>
                    </div>
                  </div>

                  <div className="grid mt-4 gap-4 items-center grid-cols-4">
                    <div className="mt-2">
                      <input
                        placeholder="Type your Request ID"
                        className="w-full border rounded-md p-2 bg-white"
                        type="text"
                        id="request-id"
                        value={requestId} // Controlled input
                        onChange={(e) => setRequestId(e.target.value)}
                      />
                    </div>
                    <div className="mt-2">
                      <input
                        className="w-full border rounded-md p-2 bg-white"
                        type="date"
                        id="start-date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)} // Capture start date
                      />
                    </div>
                    <div className="mt-2">
                      <input
                        className="w-full border rounded-md p-2 bg-white"
                        type="date"
                        id="end-date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                    <div>
                      <button
                        onClick={handleSearch}
                        className="px-6 mx-auto m-0 w-full text-center text-sm hover:bg-[#A36A2D] hover:text-white border-2 text-[#A36A2D] font-bold border-[#A36A2D] uppercase rounded-md py-2 flex items-center justify-center gap-1"
                      >
                        Search
                      </button>
                    </div>
                  </div>

                  {tabs === "all" && (
                    <section className="mb-20 mt-7 text-gray-800">
                      <div className="block rounded-lg">
                        <div className="flex overflow-x-auto flex-col">
                          <div className="sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full sm:px-6 lg:px-8">
                              <div className="overflow-hidden">
                                <table className="min-w-full mb-0">
                                  <thead className="bg-[#F4F6F9]">
                                    <tr className="rounded-lg whitespace-nowrap">
                                      <th
                                        scope="col"
                                        className="text-sm text-black font-bold px-6 py-4"
                                      >
                                        Request ID
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm text-black font-bold px-6 py-4"
                                      >
                                        Request Sent
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm text-black font-bold px-6 py-4"
                                      >
                                        Request Detail
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm text-black font-bold px-6 py-4"
                                      >
                                        Status
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm text-black font-bold px-6 py-4"
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(filteredUsers?.length > 0
                                      ? filteredUsers
                                      : users
                                    )?.map((item, index) => (
                                      <tr
                                        key={index}
                                        className="bg-white border-t rounded-md"
                                      >
                                        <th
                                          scope="row"
                                          className="text-sm font-normal px-6 py-4 whitespace-nowrap"
                                        >
                                          <p className="mb-0.5 font-medium text-black">
                                            {item?._id}
                                          </p>
                                        </th>
                                        <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-center">
                                          <span className="text-base text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline rounded-full">
                                            {new Date(
                                              item?.createdAt
                                            ).toLocaleDateString()}{" "}
                                            {/* Format the date */}
                                          </span>
                                        </td>
                                        <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                          <span className="text-base text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline rounded-full">
                                            {item?.orderId?.comment ||
                                              "No details available"}
                                          </span>
                                        </td>
                                        <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                          <span className="text-base text-black py-1 px-2.5 leading-none whitespace-nowrap rounded-full">
                                            Payment Pending{" "}
                                            {/* Update dynamically if needed */}
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}

                  {tabs === "payment" && (
                    <section className="mb-20 mt-7 text-gray-800">
                      <div className="block rounded-lg">
                        <div className="flex overflow-x-auto flex-col">
                          <div className="sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full sm:px-6 lg:px-8">
                              <div className="overflow-hidden">
                                <table className="min-w-full mb-0">
                                  <thead className="bg-[#F4F6F9]">
                                    <tr className="rounded-lg whitespace-nowrap">
                                      <th
                                        scope="col"
                                        className="text-sm text-black font-bold px-6 py-4"
                                      >
                                        Request ID
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm text-black font-bold px-6 py-4"
                                      >
                                        Request Sent
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm text-black font-bold px-6 py-4"
                                      >
                                        Request Detail
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm text-black font-bold px-6 py-4"
                                      >
                                        Status
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm text-black font-bold px-6 py-4"
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(filteredUsers?.length > 0
                                      ? filteredUsers
                                      : users
                                    )?.map((item, index) => (
                                      <tr
                                        key={index}
                                        className="bg-white border-t rounded-md"
                                      >
                                        <th
                                          scope="row"
                                          className="text-sm font-normal px-6 py-4 whitespace-nowrap"
                                        >
                                          <p className="mb-0.5 font-medium text-black">
                                            {item?._id}
                                          </p>
                                        </th>
                                        <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-center">
                                          <span className="text-base text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline rounded-full">
                                            {new Date(
                                              item?.createdAt
                                            ).toLocaleDateString()}{" "}
                                            {/* Format the date */}
                                          </span>
                                        </td>
                                        <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                          <span className="text-base text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline rounded-full">
                                            {item?.orderId?.comment ||
                                              "No details available"}
                                          </span>
                                        </td>
                                        <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                          <span className="text-base text-black py-1 px-2.5 leading-none whitespace-nowrap rounded-full">
                                            Payment Pending{" "}
                                            {/* Update dynamically if needed */}
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}

                  {tabs === "complete" && (
                    <section className="mb-20 mt-7 text-gray-800">
                      <div className="block rounded-lg">
                        <div className="flex overflow-x-auto flex-col">
                          <div className="sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full sm:px-6 lg:px-8">
                              <div className="overflow-hidden">
                                <table className="min-w-full mb-0">
                                  <thead className="bg-[#F4F6F9]">
                                    <tr className="rounded-lg whitespace-nowrap">
                                      <th
                                        scope="col"
                                        className="text-sm text-black font-bold px-6 py-4"
                                      >
                                        Request ID
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm text-black font-bold px-6 py-4"
                                      >
                                        Request Sent
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm text-black font-bold px-6 py-4"
                                      >
                                        Request Detail
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm text-black font-bold px-6 py-4"
                                      >
                                        Status
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm text-black font-bold px-6 py-4"
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(filteredUsers?.length > 0
                                      ? filteredUsers
                                      : users
                                    )?.map((item, index) => (
                                      <tr
                                        key={index}
                                        className="bg-white border-t rounded-md"
                                      >
                                        <th
                                          scope="row"
                                          className="text-sm font-normal px-6 py-4 whitespace-nowrap"
                                        >
                                          <p className="mb-0.5 font-medium text-black">
                                            {item?._id}
                                          </p>
                                        </th>
                                        <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-center">
                                          <span className="text-base text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline rounded-full">
                                            {new Date(
                                              item?.createdAt
                                            ).toLocaleDateString()}{" "}
                                            {/* Format the date */}
                                          </span>
                                        </td>
                                        <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                          <span className="text-base text-black py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline rounded-full">
                                            {item?.orderId?.comment ||
                                              "No details available"}
                                          </span>
                                        </td>
                                        <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                          <span className="text-base text-black py-1 px-2.5 leading-none whitespace-nowrap rounded-full">
                                            Payment Pending{" "}
                                            {/* Update dynamically if needed */}
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                </div>
              </div>
            </div>
            <div className=" bg-white p-2 shadow-lg w-[35%]">
              <div className=" bg-white text-center rounded-md  p-6">
                <h3 className=" text-black  font-Asap text-lg  font-semibold">
                  Account ID
                </h3>

                <h1 className=" text-orange-500 font-bold mb-3 text-3xl">
                  DIEC25032
                </h1>
                <hr />
                <ul className=" flex flex-col gap-2 p-0 mt-3">
                  <li className=" flex justify-between items-center">
                    <span className="  text-gray-400 font-medium">Name</span>
                    <span className="  capitalize font-medium text-black">
                      {`${user?.firstName} ${""} ${user?.lastName}`}
                    </span>
                  </li>
                  <li className=" flex justify-between items-center">
                    <span className="  text-gray-400 font-medium">Phone</span>
                    <span className="  font-medium text-black">
                      {user?.phone}
                    </span>
                  </li>
                  <li className=" flex justify-between items-center">
                    <span className="  text-gray-400 font-medium">
                      Address line 1
                    </span>
                    <span className="  font-medium text-black">
                      {user?.address1?.split(" ").slice(0, 4).join(" ")}...
                    </span>
                  </li>
                  <li className=" flex justify-between items-center">
                    <span className="  text-gray-400 font-medium">
                      Address line 2
                    </span>
                    <span className="  font-medium text-black">
                      {" "}
                      {user?.address2?.split(" ").slice(0, 4).join(" ")}...
                    </span>
                  </li>
                  <li className=" flex justify-between items-center">
                    <span className="  text-gray-400 font-medium">City</span>
                    <span className="  font-medium text-black">
                      {user?.city}
                    </span>
                  </li>
                  <li className=" flex justify-between items-center">
                    <span className="  text-gray-400 font-medium">
                      State / Province
                    </span>
                    <span className="  font-medium text-black">
                      {user?.state}
                    </span>
                  </li>
                  <li className=" flex justify-between items-center">
                    <span className="  text-gray-400 font-medium">
                      Zip Code
                    </span>
                    <span className="  font-medium text-black">
                      {user?.zipCode}
                    </span>
                  </li>
                  <hr />

                  <p className=" text-black font-medium text-left">
                    For better experience, please make sure to add your Account
                    ID SW25032 To the address line 2 so we can identify you
                    easily and ship your package so fast.
                  </p>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <WareHouseLocation />
    </>
  );
};

export default MyDashboard;
