import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const slides = [
  require("../../assets/images/slide-bg-1.jpg"),
  require("../../assets/images/slide-bg-2.jpg"),
  require("../../assets/images/slide-bg-1.jpg"),
  require("../../assets/images/slide01.jpg"),
];

const Carousel = ({ autoSlide = false, autoSlideInterval = 3000 }) => {
  const [curr, setCurr] = useState(0);
  const [modal, setModal] = useState(false);

  const prev = () => setCurr((curr) => (curr === 0 ? slides.length - 1 : curr - 1));
  const next = () => setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1));

  useEffect(() => {
    if (autoSlide) {
      const slideInterval = setInterval(next, autoSlideInterval);
      return () => clearInterval(slideInterval);
    }
  }, [autoSlide, autoSlideInterval]);

  return (
    <div className="relative overflow-hidden">
      <AnimatePresence initial={false} custom={curr} exitBeforeEnter={false} mode="wait">
        <motion.div
          key={curr}
          className="flex transition-opacity ease-out duration-500 sm:h-screen h-[75vh] w-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ backgroundImage: `url(${slides[curr]})`, backgroundSize: "cover", backgroundPosition: "center" }}
        >
          <motion.div
            className="absolute left-0 right-0 top-0 bg-[rgba(0,0,0,0.7)] w-full h-full text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <div className="h-full container  mx-auto sm:px-24 px-5 justify-center items-center flex">
              <motion.div
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 1 }}
              >
                {curr === 0 && (
                  <>
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      className="uppercase mt-6 text-white font-primary font-semibold text-xl w-52 rounded-md"
                    >
                      WE ARE ACTIVE
                    </motion.button>
                    <motion.h1
                      initial={{ y: 50, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.5, duration: 1, ease: "easeOut" }}
                      className="sm:text-5xl text-4xl text-[#A36A2D] dark:text-white uppercase font-medium font-primary"
                    >
                      TO SHIP YOUR PRODUCT TRUSTED
                    </motion.h1>
                    <motion.p
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 1, duration: 1, ease: "easeOut" }}
                      className="text-white pt-3"
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione dolorum voluptates ad quod eveniet
                      officiis adipisci blanditiis aliquid rem qui, odio alias aspernatur aliquam aut molestiae maxime, nemo
                      maiores doloribus?
                    </motion.p>
                  </>
                )}
                 {curr === 1 && (
                  <>
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      className="uppercase mt-6 text-white font-primary font-semibold text-xl w-52 rounded-md"
                    >
                      WE ARE ACTIVE
                    </motion.button>
                    <motion.h1
                      initial={{ y: 50, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.5, duration: 1, ease: "easeOut" }}
                      className="text-5xl text-[#A36A2D] dark:text-white uppercase font-medium font-primary"
                    >
                      TO SHIP YOUR PRODUCT TRUSTED
                    </motion.h1>
                    <motion.p
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 1, duration: 1, ease: "easeOut" }}
                      className="text-white pt-3"
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione dolorum voluptates ad quod eveniet
                      officiis adipisci blanditiis aliquid rem qui, odio alias aspernatur aliquam aut molestiae maxime, nemo
                      maiores doloribus?
                    </motion.p>
                  </>
                )}


{curr === 2 && (
                  <>
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      className="uppercase mt-6 text-white font-primary font-semibold text-xl w-52 rounded-md"
                    >
                      WE ARE ACTIVE
                    </motion.button>
                    <motion.h1
                      initial={{ y: 50, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.5, duration: 1, ease: "easeOut" }}
                      className="text-5xl text-[#A36A2D] dark:text-white uppercase font-medium font-primary"
                    >
                      TO SHIP YOUR PRODUCT TRUSTED
                    </motion.h1>
                    <motion.p
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 1, duration: 1, ease: "easeOut" }}
                      className="text-white pt-3"
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione dolorum voluptates ad quod eveniet
                      officiis adipisci blanditiis aliquid rem qui, odio alias aspernatur aliquam aut molestiae maxime, nemo
                      maiores doloribus?
                    </motion.p>
                  </>
                )}


{curr === 3 && (
                  <>
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      className="uppercase mt-6 text-white font-primary font-semibold text-xl w-52 rounded-md"
                    >
                      WE ARE ACTIVE
                    </motion.button>
                    <motion.h1
                      initial={{ y: 50, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.5, duration: 1, ease: "easeOut" }}
                      className="text-5xl text-[#A36A2D] dark:text-white uppercase font-medium font-primary"
                    >
                      TO SHIP YOUR PRODUCT TRUSTED
                    </motion.h1>
                    <motion.p
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 1, duration: 1, ease: "easeOut" }}
                      className="text-white pt-3"
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione dolorum voluptates ad quod eveniet
                      officiis adipisci blanditiis aliquid rem qui, odio alias aspernatur aliquam aut molestiae maxime, nemo
                      maiores doloribus?
                    </motion.p>
                  </>
                )}
                
              </motion.div>
            </div>
          </motion.div>
        </motion.div>
      </AnimatePresence>

      <div className="absolute inset-0 flex items-center justify-between p-4">
        <button onClick={prev} className="p-3 rounded-sm shadow bg-[#333537] text-gray-800 hover:bg-[#a36a2d]">
          <FaAngleLeft className="text-white" size={35} />
        </button>
        <button onClick={next} className="p-3 rounded-sm shadow bg-[#333537] text-gray-800 hover:bg-[#a36a2d]">
          <FaAngleRight className="text-white" size={35} />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
