import React, { useState } from "react";
import { Base_url } from "../../../utils/Base_url";
import axios from "axios";
import Modal from "../../../components/myModal/Modal";
import { MdClose } from "react-icons/md";
import { useForm } from "react-hook-form";

const AccountAddressBook = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Use the useForm hook to create a form instance
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const storedUser = localStorage.getItem("diec_user_id");


  console.log(storedUser);
  

  let id;
  try {
    // Attempt to parse the JSON string
    id = storedUser ? JSON.parse(storedUser) : {};
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }

  const onSubmit = (data) => {
    console.log(data);

    const params = {
      userId: storedUser?._id,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      state: data.state,
      zipCode: data.zipCode,
      country: data.country,
      address1: data.addressLine1, // Updated to match form field names
      address2: data.addressLine2, // Updated to match form field names
    };

    axios
      .post(`${Base_url}/address/create`, params) // Fixed URL template string
      .then((res) => {
        console.log(res);
        closeModal(); // Close the modal on successful submission
      })
      .catch((error) => {
        console.error("Error submitting address:", error);
      });
  };

  return (
    <div className="bg-[#F4F6F9]">
      <div className="container px-16 py-12 mx-auto">
        <div className="bg-white p-6 rounded-md">
          <div className="flex justify-between gap-10">
            <div className="w-[65%]">
              <h1 className="text-black text-2xl font-bold">Address Book</h1>
              <p className="text-black text-sm pt-3 font-normal">
                You can store any number of addresses that you would like to
                ship to. Your default shipping address is the one you registered
                with. You can change that at any time.
              </p>
              <button
                onClick={openModal}
                className="px-7 mt-5 text-center text-sm hover:bg-[#A36A2D] hover:text-white border-2 text-[#A36A2D] border-[#A36A2D] uppercase rounded-lg font-Asap font-medium py-3 flex items-center justify-center gap-1"
              >
                + Add New Address
              </button>
            </div>
            <div className="w-[35%]">
              <div className="bg-white p-2 shadow-lg">
                <div className="bg-white text-center rounded-md p-6">
                  <h3 className="text-black font-Asap text-lg font-semibold">
                    Account ID
                  </h3>
                  <h1 className="text-orange-500 font-bold mb-3 text-3xl">
                    DIEC25032
                  </h1>
                  <hr />
                  <ul className="flex flex-col gap-2 p-0 mt-3">
                    <li className="flex justify-between items-center">
                      <span className="text-gray-400 font-medium">Name</span>
                      <span className="font-medium text-black">
                        ghulam mustafa
                      </span>
                    </li>
                    <li className="flex justify-between items-center">
                      <span className="text-gray-400 font-medium">Phone</span>
                      <span className="font-medium text-black">
                        +1 844274633
                      </span>
                    </li>
                    <li className="flex justify-between items-center">
                      <span className="text-gray-400 font-medium">
                        Address line 1
                      </span>
                      <span className="font-medium text-black">
                        183 Edgemoor Road
                      </span>
                    </li>
                    <li className="flex justify-between items-center">
                      <span className="text-gray-400 font-medium">
                        Address line 2
                      </span>
                      <span className="font-medium text-black">DIEC25032</span>
                    </li>
                    <li className="flex justify-between items-center">
                      <span className="text-gray-400 font-medium">City</span>
                      <span className="font-medium text-black">Wilmington</span>
                    </li>
                    <li className="flex justify-between items-center">
                      <span className="text-gray-400 font-medium">
                        State / Province
                      </span>
                      <span className="font-medium text-black">Delaware</span>
                    </li>
                    <li className="flex justify-between items-center">
                      <span className="text-gray-400 font-medium">
                        Zip Code
                      </span>
                      <span className="font-medium text-black">1809</span>
                    </li>
                    <hr />
                    <p className="text-black font-medium text-left">
                      For better experience, please make sure to add your
                      Account ID SW25032 to the address line 2 so we can
                      identify you easily and ship your package so fast.
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        className={"max-w-2xl rounded-md"}
      >
        {/* Modal Content */}
        <div className="">
          <div className="p-3 flex justify-between items-center">
            <h1 className="capitalize text-2xl font-bold">Add New Address</h1>
            <MdClose
              size={25}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-5">
            <div className="flex gap-5 flex-wrap">
              <div className="w-[48%]">
                <label className="">First Name</label>
                <input
                  placeholder=""
                  className={`border mt-2 rounded-sm w-full py-3 ${
                    errors.firstName ? "border-red-600" : ""
                  }`}
                  {...register("firstName", { required: "First Name is required" })}
                />
                {errors.firstName && (
                  <p className="text-red-600 text-sm font-semibold capitalize">
                    {errors.firstName.message}
                  </p>
                )}
              </div>
              <div className="w-[48%]">
                <label className="">Last Name</label>
                <input
                  placeholder=""
                  className={`border mt-2 rounded-sm w-full py-3 ${
                    errors.lastName ? "border-red-600" : ""
                  }`}
                  {...register("lastName", {
                    required: "Last Name is required",
                  })}
                />
                {errors.lastName && (
                  <p className="text-red-600 text-sm font-semibold capitalize">
                    {errors.lastName.message}
                  </p>
                )}
              </div>
              <div className="w-full">
                <label className="">Country</label>
                <select
                  className={`border mt-2 rounded-sm w-full py-3 ${
                    errors.country ? "border-red-600" : ""
                  }`}
                  {...register("country", { required: "Country is required" })}
                >
                  <option value="">Select Country</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="India">India</option>
                  <option value="China">China</option>
                </select>
                {errors.country && (
                  <p className="text-red-600 text-sm font-semibold capitalize">
                    {errors.country.message}
                  </p>
                )}
              </div>
              <div className="w-full">
                <label className="">State / Province</label>
                <select
                  className={`border mt-2 rounded-sm w-full py-3 ${
                    errors.state ? "border-red-600" : ""
                  }`}
                  {...register("state", {
                    required: "State/Province is required",
                  })}
                >
                  <option value="">Select Province</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Khyber Pakhtunkhwa">Khyber Pakhtunkhwa</option>
                  <option value="Sindh">Sindh</option>
                </select>
                {errors.state && (
                  <p className="text-red-600 text-sm font-semibold capitalize">
                    {errors.state.message}
                  </p>
                )}
              </div>
              <div className="w-[48%]">
                <label className="">Address Line 1</label>
                <input
                  placeholder=""
                  className={`border mt-2 rounded-sm w-full py-3 ${
                    errors.addressLine1 ? "border-red-600" : ""
                  }`}
                  {...register("addressLine1", {
                    required: "Address Line 1 is required",
                  })}
                />
                {errors.addressLine1 && (
                  <p className="text-red-600 text-sm font-semibold capitalize">
                    {errors.addressLine1.message}
                  </p>
                )}
              </div>
              <div className="w-[48%]">
                <label className="">Address Line 2</label>
                <input
                  placeholder=""
                  className={`border mt-2 rounded-sm w-full py-3 ${
                    errors.addressLine2 ? "border-red-600" : ""
                  }`}
                  {...register("addressLine2")}
                />
                {errors.addressLine2 && (
                  <p className="text-red-600 text-sm font-semibold capitalize">
                    {errors.addressLine2.message}
                  </p>
                )}
              </div>
              <div className="w-[48%]">
                <label className="">Zip Code</label>
                <input
                  placeholder=""
                  className={`border mt-2 rounded-sm w-full py-3 ${
                    errors.zipCode ? "border-red-600" : ""
                  }`}
                  {...register("zipCode", { required: "Zip Code is required" })}
                />
                {errors.zipCode && (
                  <p className="text-red-600 text-sm font-semibold capitalize">
                    {errors.zipCode.message}
                  </p>
                )}
              </div>
              <div className="w-[48%]">
                <label className="">Phone</label>
                <input
                  placeholder=""
                  className={`border mt-2 rounded-sm w-full py-3 ${
                    errors.phone ? "border-red-600" : ""
                  }`}
                  {...register("phone", { required: "Phone number is required" })}
                />
                {errors.phone && (
                  <p className="text-red-600 text-sm font-semibold capitalize">
                    {errors.phone.message}
                  </p>
                )}
              </div>
            </div>
            <div className="flex justify-end gap-3 mt-6">
              <button
                type="submit"
                className="px-7 text-center text-sm hover:bg-[#A36A2D] hover:text-white border-2 text-[#A36A2D] border-[#A36A2D] uppercase rounded-lg font-Asap font-medium py-3 flex items-center justify-center gap-1"
              >
                Add Address
              </button>
              <button
                onClick={closeModal}
                className="px-7 text-center text-sm hover:bg-red-600 hover:text-white border-2 text-red-600 border-red-600 uppercase rounded-lg font-Asap font-medium py-3 flex items-center justify-center gap-1"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AccountAddressBook;
