import React, { useState } from 'react';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import { toast } from 'react-toastify';

const ColleagueBox = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isNextVisible, setIsNextVisible] = useState(false);
  const [position, setPosition] = useState('-bottom-40');
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleMove = () => {
    setPosition(position === '-bottom-40' ? '-bottom-2' : '-bottom-40');
  };

  const handleNext = () => {
    if (selectedNumber !== null) {
      setIsNextVisible(true);
    } else {
     
      toast.error('Please select a number.')
    }
  };

  const handleClose = () => {
    setIsVisible(false);
    setIsNextVisible(false);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handlePhoneNumberSubmit = () => {
    const phoneNumberPattern = /^[0-9]{10}$/;
    if (phoneNumberPattern.test(phoneNumber)) {
      setIsSubmitted(true);
      setTimeout(() => {
        handleClose();
        setIsSubmitted(false);
      }, 2000);
    } else {
     
      toast.error('Please enter a valid 10-digit phone number.')
    }
  };

  return (
    <>
      {isVisible && !isNextVisible && (
        <div className='flex justify-center'>
          <div
          className={`fixed  mx-auto z-50 sm:w-[650px] w-[400px] bg-white border-[#A36A2D] border-[3px] shadow-lg rounded-t-lg transform transition-transform duration-300 ${position}`}
        >
          <div className="border-[3px] w-12 flex justify-center border-[#A36A2D] rounded-tr-xl rounded-tl-xl float-right -translate-x-5 -translate-y-10 border-b-0 bg-white items-center h-10">
            <button
              onClick={handleMove}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              {position === '-bottom-40' ? <FaAngleUp color='#A36A2D' size={20} /> : <FaAngleDown color='#A36A2D' size={20} />}
            </button>
          </div>
          <div className="p-4">
            <h2 className="text-black text-center font-medium ">
              How likely are you to recommend Diec World to a friend or a colleague?
            </h2>
            <div className='w-full flex gap-2 pt-4 items-center justify-between'>
              {Array.from({ length: 10 }, (_, i) => (
                <div
                  key={i + 1}
                  onClick={() => setSelectedNumber(i + 1)}
                  className={`border-[3px] flex justify-center items-center hover:text-white border-[#a36a2d] hover:bg-[#a36a2d] w-12 h-12 rounded-md cursor-pointer ${selectedNumber === i + 1 ? 'bg-[#a36a2d] text-white' : ''}`}
                >
                  <p className='m-0 font-medium'>{i + 1}</p>
                </div>
              ))}
            </div>
            <div className='flex justify-between items-center'>
              <p className='text-sm text-black'>Not likely at all</p>
              <p className='text-sm text-black'>Extremely likely</p>
            </div>
            <button
              onClick={handleNext}
              className="px-6 text-center mx-auto mt-7 text-sm uppercase rounded-sm py-2.5 flex items-center justify-center gap-1 bg-[#A36A2D] text-white"
            >
              Next
            </button>
          </div>
        </div>
        </div>
      )}
      {isNextVisible && !isSubmitted && (
        <div className='   flex justify-center'>
          <div
          className={`fixed  mx-auto z-50 w-[650px] bg-white border-[#A36A2D] border-[3px] shadow-lg rounded-t-lg transform transition-transform duration-300 -bottom-2`}
        >
          <div className="border-[3px] w-12 flex justify-center border-[#A36A2D] rounded-tr-xl rounded-tl-xl float-right -translate-x-5 -translate-y-10 border-b-0 bg-white items-center h-10">
            <button
              onClick={handleClose}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <FaAngleDown color='#A36A2D' size={20} />
            </button>
          </div>
          <div className="p-4">
            <h2 className="text-black text-center font-medium text-lg">
              Would you like to mention your Phone Number?
            </h2>
            <div className="mt-4">
              <input
                type="tel"
                placeholder="Phone number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                className="w-full px-4 py-2 border shadow-md border-gray-300 rounded-md focus:outline-none focus:border-[#A36A2D]"
              />
            </div>
            <button
              onClick={handlePhoneNumberSubmit}
              className="px-6 text-center mx-auto mt-7 text-sm uppercase rounded-sm py-2.5 flex items-center justify-center gap-1 bg-[#A36A2D] text-white"
            >
              Send
            </button>
          </div>
        </div>
        </div>
      )}
      {isSubmitted && (
        // <div
        //   className={`fixed right-[30%] mx-auto z-50 w-[650px] bg-white border-[#A36A2D] border-[3px] shadow-lg rounded-t-lg transform transition-transform duration-300 -bottom-2`}
        // >
        //   <div className="border-[3px] w-12 flex justify-center border-[#A36A2D] rounded-tr-xl rounded-tl-xl float-right -translate-x-5 -translate-y-10 border-b-0 bg-white items-center h-10">
        //     <button
        //       onClick={handleClose}
        //       className="text-gray-500 hover:text-gray-700 focus:outline-none"
        //     >
        //       <FaAngleDown color='#A36A2D' size={20} />
        //     </button>
        //   </div>
        //   <div className="p-4">
        //     <h2 className="text-black text-center font-medium text-lg">
        //       Thank you for your response!
        //     </h2>
        //   </div>
        // </div>
         toast.success('  Thank you for your response!')
      )}
    </>
  );
};

export default ColleagueBox;
