import React, { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsappChat = ({ phoneNumber, message }) => {
  const [isHovered, setIsHovered] = useState(false);

  

  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div 
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        cursor: 'pointer',
        display: 'inline-block',
        position: 'fixed',
        bottom: '20px',
        left: '20px',
        background: isHovered ? 'white' : '#25D366',
        borderRadius: '50%',
        padding: '10px',
        zIndex: 1000, // Ensures it stays on top of other elements
        transition: 'background 0.3s ease',
      }}
    >
      <FaWhatsapp size={30} color={isHovered ? '#25D366' : 'white'} />
    </div>
  );
};

export default WhatsappChat;
