import React from 'react'
import { FaArrowRightLong } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

const OurServices = () => {
  return (
    <div>



        <div className=' container mx-auto  pb-16'>
        <div className=' text-center'>
        <h1 className=" text-[#A36A2D] text-5xl font-Asap  font-semibold">
          
          Our Services


        </h1>
        </div>

        <div className=' grid  shadow-xl bg-[#F7F8FD] mt-6 rounded-md sm:grid-cols-2 grid-cols-1'>
            <div className=' border-r border-b '>


                <div className=' p-12 '>

                <img src={require('../../assets/images/location_pin.png')} alt=''  className=' w-24' />
                   

                    <h1 className=' text-black font-semibold text-2xl font-Asap pt-3'>Free US & UAE Address</h1>
                    <p className=' text-gray-500 font-Asap'>Sign up and register for a life-time shipping address and start enjoying shopping right away

</p>



                </div>

            </div>
            <div className=' border-b '>


<div className=' p-12'>

<img src={require('../../assets/images/consolidation.png')} alt=''  className=' w-24' />
   

    <h1 className=' text-black font-semibold text-2xl font-Asap pt-3'>Package Consolidation</h1>
    <p className=' text-gray-500 font-Asap'>We will combine all packages and ship them as one to you so you can save main 80% on shipping charges 

</p>



</div>

</div>
<div className='  border-r'>


<div className=' p-12'>

<img src={require('../../assets/images/cloud.png')} alt=''  className=' w-24' />
   

    <h1 className=' text-black font-semibold text-2xl font-Asap pt-3'>Free Storage</h1>
    <p className=' text-gray-500 font-Asap'>We provide you with a free storage service so you can shop more & save on shipping you consolidate all shipments together 

</p>



</div>

</div>

<div className=' '>


<div className=' p-12'>

<img src={require('../../assets/images/cloud.png')} alt=''  className=' w-24' />
   

    <h1 className=' text-black font-semibold text-2xl font-Asap pt-3'>Buy it for me</h1>
    <p className=' text-gray-500 font-Asap'>We provide you "Buy it for me" Service was designed especially for those who need help purchasing their loved items online.

</p>



</div>

</div>
        </div>


        <button className=" justify-center mt-7  mx-auto  bg-[#A36A2D] text-white  py-3 px-6 rounded-sm text-lg flex gap-2 items-center  font-Asap font-semibold">
                  SEE ALL SERVICES <FaArrowRightLong color="white"  />
                </button>

        </div>
      
    </div>
  )
}

export default OurServices
