import { useEffect, useState } from "react";
import Pricing from "../../components/Pricing";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";

function PlansScreen() {
  const [allPlan, setAllPlan] = useState([]);

  useEffect(() => {
    axios
      .get(`${Base_url}/plan/get`)
      .then((res) => {
        setAllPlan(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const backgroundColors = [
    "bg-gradient-to-l from-[#A36A2D] to-[#333537]",
    "bg-gradient-to-r from-green-200 to-green-300",
    "bg-gradient-to-l from-pink-400 to-pink-200",
  ];

  return (
    <div className="bg-[#F7F8FD]">
      <div className="text-center pb-10 pt-12">
        <h1 className="text-black font-semibold sm:text-4xl text-2xl">
          Choose a Membership Plan that is
        </h1>
        <h2 className="text-[#A36A2D] font-semibold pt-2 sm:text-5xl text-4xl">
          Suitable for You
        </h2>
      </div>

      <div className="min-h-screen mx-auto w-full flex justify-center items-center bg-[#F7F8FD] flex-wrap">
        {allPlan?.map((item, index) => {
          const backgroundClass = backgroundColors[index % backgroundColors.length];

          return (
            <div className="pricing-component m-8" key={index}>
              <Pricing
                data={item?.features}
                price={item?.price}
                duration={item?.duration}
                currency="$"
                buttonContent="Sign Up"
                subTitle="Great for starters"
                priceText="Discover how to create your first projects."
                headerText={item?.name}
                background={backgroundClass}
                Link_package={item?._id}
                getData={item}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PlansScreen;
