import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: null,
  plan: null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.userInfo = action.payload;
    },
    addPlan: (state, action) => {
        state.plan = action.payload;
      },
    removeUser: (state, action) => {
      state.userInfo = null;
    },
  },
});

export const {
  addUser,
  removeUser,
  addPlan
} = authSlice.actions;

export default authSlice.reducer;
