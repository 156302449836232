import React from "react";
import { motion } from "framer-motion";
import { FaArrowRightLong } from "react-icons/fa6";

const HowItsWorks = () => {
  const cards = [
    {
      imgSrc: require("../../assets/images/registered.png"),
      title: "Register & Get Your Free Address",
      description:
        "Sign up now and instantly get your own free US and UAE shipping addresses and enjoy shopping. No set up or hidden fees.",
    },
    {
      imgSrc: require("../../assets/images/location.png"),
      title: "Shop your favorite brands and enjoy!",
      description:
        "Shop your favorite products online from any US and UAE stores and use your ShopiniWorld address when you check out.",
    },
    {
      imgSrc: require("../../assets/images/van.png"),
      title: "Fast Worldwide Delivery",
      description:
        "Shop and save min 80% on shipping fees. We offer a very competitive shipping prices specially designed for you.",
    },
  ];

  return (
    <div id="howSection" className="bg-Blog">
      <div className="container px-6 sm:px-0 py-14 mx-auto">
        <div className="text-center">
          <h1 className="text-white sm:text-5xl  text-4xl font-Asap font-semibold">
            HOW IT WORKS
          </h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 pt-20">
          {cards.map((card, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
              className="bg-white  text-black bg-no-repeat bg-cover bg-center rounded-md shadow-xl p-10 hover:bg-[#A36A2D] hover:text-white group"
            >
              <div>
                <img
                  src={card.imgSrc}
                  alt={card.title}
                  className="w-24 transition-transform group-hover:filter group-hover:brightness-0 group-hover:invert"
                />
              </div>
              <h3 className="pt-5  text-2xl font-semibold font-Asap">
                {card.title}
              </h3>
              <p className="text-gray-500 group-hover:text-white font-Asap pt-2">
                {card.description}
              </p>
            </motion.div>
          ))}
        </div>
        <div className="flex justify-center py-8">
          <button className="bg-[#A36A2D] text-white py-3 px-6 rounded-sm text-lg flex gap-2 items-center font-Asap font-semibold">
            KNOW MORE <FaArrowRightLong color="white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default HowItsWorks;
