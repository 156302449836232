import React from "react";
import { motion } from "framer-motion";

const HeroBottom = () => {
  const cards = [
    {
      imgSrc: require("../../assets/images/calculator.png"),
      title: "Calculate Rates",
      description:
        "Sea container delivery was the first shipping method used by logistic companies because of its multiple engines ipsum...",
    },
    {
      imgSrc: require("../../assets/images/shipped.png"),
      title: "Track Shipment",
      description:
        "Sea container delivery was the first shipping method used by logistic companies because of its multiple engines ipsum...",
    },
    {
      imgSrc: require("../../assets/images/address_shipping.png"),
      title: "Shipping Addresses",
      description:
        "Sea container delivery was the first shipping method used by logistic companies because of its multiple engines ipsum...",
    },
  ];

  return (
    <div className="container px-6 sm:px-0 mx-auto">
      <div className="grid z-50 gap-8 -translate-y-14 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {cards.map((card, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
            className="bg-white  text-black bg-no-repeat bg-cover bg-center rounded-md shadow-xl p-10 hover:bg-[#A36A2D] hover:text-white group"
          >
            <div>
              <div>
                <img
                  src={card.imgSrc}
                  alt={card.title}
                  className="w-24 transition-transform group-hover:filter group-hover:brightness-0 group-hover:invert"
                />
              </div>
              <div className="pt-3">
                <p className="m-0 font-semibold text-2xl font-Asap">
                  {card.title}
                </p>
                <p className="text-sm pt-4">{card.description}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default HeroBottom;
