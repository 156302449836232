import React from 'react'
import { Link } from 'react-router-dom'
import WareHouseLocation from '../../components/warehouseLocation'
import Image1 from '../../assets/images/consol1.svg'
import Image2 from '../../assets/images/consol2.svg'
import Image3 from '../../assets/images/consol3.svg'
const ItemConsolidation = () => {
  return (
    <div>
        <div className=' bg-AboutUs bg-center  bg-cover w-full  flex  items-center h-[40vh]'>
       <div className=' container mx-auto sm:px-0 px-4'>
       <h1  className='  text-white font-semibold text-3xl'>ITEM CONSOLIDATION</h1>
       <div className=' flex gap-2'>
        <Link to={''} className='  text-[#A36A2D] font-semibold'>Home</Link>
        <p className=' m-0  text-gray-400'>/ Item Consolidation</p>
       </div>
       </div>
       </div>

       
       <div className=' mx-auto py-14  container'>
         <div className=' text-center'>
         <h1 className=' text-[#A36A2D] text-4xl font-Asap  font-semibold'>How does consolidation work</h1>

         </div>




         <div className=' py-12 gap-12 grid sm:grid-cols-3 grid-cols-1'>
           <div  className=' bg-white shadow-xl relative p-5 rounded-xl'>
              <div className=' w-56 '>
                <img src={require('../../assets/images/step-1.png')}  className=' w-full' alt='' />


                <div className=' absolute top-32 right-6'>
                    <img src={require('../../assets/images/steps.png')} />
                </div>
              </div>

              <div>
                <p className=' text-black font-semibold text-lg'>When you shop many products from your loved US brands, packages will come in seperate boxes with many unnecessary packaging.</p>
              </div>

           </div>

           <div  className=' bg-white relative shadow-xl p-5 rounded-xl'>
              <div className=' w-56'>
                <img src={require('../../assets/images/step-2.png')}  className=' w-full' alt='' />
                <div className=' absolute top-32 right-6'>
                    <img src={require('../../assets/images/steps.png')} />
                </div>
              </div>

              <div>
                <p className=' text-black font-semibold text-lg'>We will open each box and remove unnecessary packing, to combine your products into 1 box

</p>
              </div>

           </div>


           <div  className=' bg-white shadow-xl p-5 rounded-xl'>
              <div className=' w-56'>
                <img src={require('../../assets/images/step-3.png')}  className=' w-full' alt='' />
              </div>

              <div>
                <p className=' text-black font-semibold text-lg'>Now your package will be ready for shipping. You will be saving a lot on your shipping fees.



</p>
              </div>

           </div>




           <div  className=' bg-white  p-5 rounded-xl'>

            <h3 className=' text-[#A36A2D] font-bold  text-center text-lg pb-4'>SHIPPING 3 SINGLE BOXES
            </h3>
              <div className=''>
                <img src={Image1}  className=' w-full' alt='' />
              </div>
              <h3 className=' text-[#A36A2D] pt-2 font-bold  text-center text-lg pb-4'>SHIPPING FEE: $138*

              </h3>
           

           </div>
           <div  className=' bg-white  p-5 rounded-xl'>
           <h3 className=' text-[#A36A2D] font-bold  text-center text-lg pb-4'>BOX CONSOLIDATION

           </h3>
              <div className=''>
                <img src={Image2}  className=' w-full' alt='' />
              </div>

              <h3 className=' text-[#A36A2D] font-bold  text-center text-lg pb-4'>SHIPPING FEE: $80*


</h3>

           </div>
           <div  className=' bg-white  p-5 rounded-xl'>
           <h3 className=' text-[#A36A2D] font-bold  text-center text-lg pb-4'>ITEM CONSOLIDATE INTO 1 BOX



</h3>
              <div className=''>
                <img src={Image3}  className=' w-full' alt='' />
              </div>

              <h3 className=' text-[#A36A2D] font-bold  text-center text-lg pb-4'>SHIPPING FEE: $56*




</h3>

           </div>
           
         </div>


       </div>

       <WareHouseLocation/>
    </div>
  )
}

export default ItemConsolidation
