import React from "react";
import WareHouseLocation from "../../components/warehouseLocation";
import { Link } from "react-router-dom";

const OurServices = () => {
  return (
    <div>
        <div className=' bg-AboutUs bg-center  bg-cover w-full  flex  items-center h-[40vh]'>
       <div className=' container mx-auto sm:px-0 px-4'>
       <h1  className='  text-white font-semibold text-3xl'>OUR SERVICES</h1>
       <div className=' flex gap-2'>
        <Link to={''} className='  text-[#A36A2D] font-semibold'>Home</Link>
        <p className=' m-0  text-gray-400'>/ Our Services</p>
       </div>
       </div>
       </div>

      <div className=" container py-12 mx-auto">
        <div className=" text-center">
          <h1 className=" text-[#A36A2D] text-4xl font-Asap  font-semibold">
            Services You Will Love
          </h1>
          <p className=" font-Asap">
            We have designed many value added services to help you reduce your
            shipping costs and improve your shipping experience.
          </p>
          <p className="">These may vary based on your membership selection</p>
        </div>

        <div className=" grid sm:grid-cols-3 grid-cols-2 mb-6 shadow-xl bg-[#F7F8FD] rounded-md    mt-10">
          <div className="   border-b p-7 ">
            <div>
              <img
                src={require("../../assets/images/location_pin.png")}
                className=" w-20"
                alt=""
              />
            </div>
            <h3 className=" pt-3 text-black  font-semibold text-xl font-Asap">
              Free US & UAE Address
            </h3>
            <p className="    font-Asap text-sm">
              Sign up and register for a life-time shipping address and start
              enjoying shopping right away
            </p>
          </div>
          <div className="   border-b p-7 border-l">
            <div>
              <img
                src={require("../../assets/images/camera.png")}
                className=" w-20"
                alt=""
              />
            </div>
            <h3 className=" pt-3 text-black  font-semibold text-xl font-Asap">
            Items photo
            </h3>
            <p className="    font-Asap text-sm">
            We will take couple of free snapshots for your purchased items once we receive them in our warehouse so you can lay back and relax!
            </p>
          </div>
          
          <div className="   border-b p-7  border-l ">
            <div>
              <img
                src={require("../../assets/images/insurance.png")}
                className=" w-20"
                alt=""
              />
            </div>
            <h3 className=" pt-3 text-black  font-semibold text-xl font-Asap">
            Insurance
            </h3>
            <p className="    font-Asap text-sm">
            We can insure your packages in case you would like to make sure they arrive with no damage risks
            </p>
          </div>
          <div className="   p-7">
            <div>
              <img
                src={require("../../assets/images/24-hours-support.png")}
                className=" w-20"
                alt=""
              />
            </div>
            <h3 className=" pt-3 text-black  font-semibold text-xl font-Asap">
            24/7 Support
            </h3>
            <p className="    font-Asap text-sm">
            We have a dedicated team to support all your needs and to answer all your questions at any time

</p>
          </div>
          <div className="    border-l p-7">
            <div>
              <img
                src={require("../../assets/images/consolidation.png")}
                className=" w-20"
                alt=""
              />
            </div>
            <h3 className=" pt-3 text-black  font-semibold text-xl font-Asap">
            Item consolidation
            </h3>
            <p className="    font-Asap text-sm">
            We can also receive all your packages, open them, and then combine and items in one package. You will even achieve more than 80% in reductions on shipping fees            </p>
          </div>
          <div className="   border-l p-7 ">
            <div>
              <img
                src={require("../../assets/images/cloud.png")}
                className=" w-20"
                alt=""
              />
            </div>
            <h3 className=" pt-3 text-black  font-semibold text-xl font-Asap">
            Free Storage
            </h3>
            <p className="    font-Asap text-sm">
            We provide you with a free storage service so you can shop more & save on shipping you consolidate all shipments together

  </p>
          </div>
       
          
        </div>
        <button className=" justify-center mt-7  mx-auto  bg-[#A36A2D] text-white  py-3 px-6 rounded-sm text-lg flex gap-2 items-center  font-Asap font-semibold">
                  SIGN UP
                </button>
      </div>

      <WareHouseLocation />
    </div>
  );
};

export default OurServices;
