import React from "react";
import WareHouseLocation from "../../components/warehouseLocation";
import Brands from "../../components/brands";
import { FiPackage } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { LuUser2 } from "react-icons/lu";
import { BiLike } from "react-icons/bi";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <>
    
      <div className=' bg-AboutUs bg-center  bg-cover w-full  flex  items-center h-[40vh]'>
       <div className=' container mx-auto'>
       <h1  className='  text-white font-semibold text-3xl'>CONTACT US</h1>
       <div className=' flex gap-2'>
        <Link to={''} className='  text-[#A36A2D] font-semibold'>Home</Link>
        <p className=' m-0  text-gray-400'>/ Contact Us</p>
       </div>
       </div>
       </div>
      <div className=" container  py-20 mx-auto">
        <div className=" grid   grid-cols-2 gap-10">
          <div className=" pt-12">
            <div className=" relative">
              <div className=" absolute -top-16  z-30">
                <img
                  src={require("../../assets/images/about-2.png")}
                  className="  rounded-full"
                />
              </div>
              <div className=" absolute right-0  top-0">
                <img src={require("../../assets/images/about-1.png")} />
              </div>
            </div>
          </div>
          <div>
            <span className=" text-[#A36A2D]     font-bold text-lg">
              WE SPECIALISE IN THE TRANSPORTATION
            </span>

            <h1 className=" font-bold  text-black text-5xl">
              Manage your Complex Logistics as can Focus
            </h1>

            <ul className=" flex  flex-col gap-2 p-0 pt-6">
              <li>
                <p className=" text-gray-400 text-sm">
                  Diec World was established to serve international shoppers get
                  what they need and wants from overseas markets like the US,
                  Turkey and Dubai. It was also established as a mean for
                  expatriates to receive shopping catalogues and mail overseas.
                  Diec World tends to serve the international shopping needs for
                  those who resides within the Gulf region, this includes;
                  United Arab Emirates, Saudi Arabia, Bahrain, Qatar, Oman, and
                  Kuwait. We have also plans to extend our services to cover
                  Jordan, Lebanon, Egypt and Iraq.
                </p>
              </li>
              <li>
                <p className=" text-gray-400 text-sm">
                  {" "}
                  To deliver on our service promises, Diec World has partnered
                  with the top in class shopping outlets who would provide
                  special discounted rates on fashion merchandises that suites
                  the needs of all customers. It has also partnered with
                  international courier service providers and negotiated the
                  best rates on behalf of all customers so they can receive
                  their shipments smoothly and hassle free.
                </p>
              </li>
              <li>
                <p className=" text-gray-400 text-sm">
                  We love making it easy for our customers shop US stores.
                  Therefore, Diec World has partnered with the most popular
                  stores to give customers access to a wide range of quality
                  products accompanied with a delivery service to any place in
                  the world. We tend to bring the best deals to the hands of our
                  customers so they can enjoy the best rates and discounts, and
                  hence enjoy on massive savings.
                </p>
              </li>
              <li>
                <p className=" text-gray-400 text-sm">
                  We have passion for delivering excellence in shopping and
                  shipping! Our mission is to deliver happiness and delight our
                  customers with every single purchase they make, and with every
                  package we deliver to them. We have topped that with our
                  superior customer service.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>





<div className=" bg-Fact_bg bg-cover bg-center  bg-no-repeat">
      <div className=" container mx-auto py-14">

        <div className=" text-center">
            <span className=" text-black text-xl font-semibold">Delivery Anything            </span>
            
            <h1 className=' text-[#A46A2D] font-bold text-center text-4xl'>Exclusive Cargo Gallery           </h1>
        </div>
        <div className=" grid  gap-7 pt-14 grid-cols-4">
            <div className=" bg-white p-6 shadow-xl rounded-md">
            <FiPackage size={50} color="#A36A2D" />
                <h1 className=" pt-2 font-bold text-4xl text-[#A36A2D]">3,560 km</h1>
                <h6 className=" pt-2 font-bold text-xl">Package Delivery</h6>
                <p className=" pt-3 text-gray-500">Express delivery is an innaiv service effective logistics solution</p>
            </div>
            <div className=" bg-white p-6 shadow-xl rounded-md">
        
            <IoLocationOutline size={50} color="#A36A2D"  />
                <h1 className=" pt-2 font-bold text-4xl text-[#A36A2D]">195</h1>
                <h6 className=" pt-2 font-bold text-xl">Countries Covered</h6>
                <p className=" pt-3 text-gray-500">Express delivery is an innaiv service effective logistics solution

</p>
            </div>
            <div className=" bg-white p-6 shadow-xl rounded-md">
            
            <LuUser2  size={50} color="#A36A2D"  />
                <h1 className=" pt-2 font-bold text-4xl text-[#A36A2D]">455 k                </h1>
                <h6 className=" pt-2 font-bold text-xl">Happy Customer                </h6>
                <p className=" pt-3 text-gray-500">Express delivery is an innaiv service effective logistics solution

</p>
            </div>
            <div className=" bg-white p-6 shadow-xl rounded-md">
         
            <BiLike size={50} color="#A36A2D" />

                <h1 className=" pt-2 font-bold text-4xl text-[#A36A2D]">99 Yr
                </h1>
                <h6 className=" pt-2 font-bold text-xl">Year Experience
                </h6>
                <p className=" pt-3 text-gray-500">Express delivery is an innaiv service effective logistics solution</p>
            </div>
        </div>

      </div>
      </div>
      <Brands/>
      <WareHouseLocation />
    </>
  );
};

export default AboutUs;
