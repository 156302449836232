import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Modal from "../../components/myModal/Modal";
import { FaRegCircleCheck } from "react-icons/fa6";
import { Base_url } from "../../utils/Base_url";
import { toast } from "react-toastify";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import paypal from "../../assets/images/PayPal.svg";

const BuyForModal = ({ isModalOpen, setIsModalOpen, closeModal }) => {
  const [openState, setOpenState] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [loading, setLoader] = useState(false);
  const user = JSON.parse(localStorage.getItem("diec_user_id"));
  const validationSchema = Yup.object().shape({
    productUrl: Yup.string().required("Product URL is required"),
    productName: Yup.string().required("Product Name is required"),
    unitPrice: Yup.number().required("Unit Price is required"),
    quantity: Yup.number().required("Quantity is required"),
    shippingFees: Yup.number().required("Shipping Fees are required"),
    color: Yup.string(),
    size: Yup.string(),
    description: Yup.string(),
    attachPictures: Yup.mixed(),
  });

  const handleSubmit = async (values) => {
    console.log(values);
    setLoader(true);
    const parms = new FormData();
    parms.append("userId", user?._id);
    parms.append("productUrl", values.productUrl);
    parms.append("productName", values.productName);
    parms.append("unitPrice", values.unitPrice);
    parms.append("quantity", values.quantity);
    parms.append("shipFee", values.shippingFees);
    parms.append("color", values.color);
    parms.append("size", values.size);
    parms.append("image", values.attachPictures);
    parms.append("comment", values.description);
    parms.append("status", "pending");
    parms.append("paymentStatus", "unpaid");
    parms.append("tracking", values.tracking);

    axios
      .post(`${Base_url}/order/create`, parms)
      .then((response) => {
        console.log(response);

        if (response.data.status === "ok") {
          console.log(response.data.data.email);
          setOpenState(true);
          setFormValues(response.data.data);
          setLoader(false);
        } else {
          toast(response.data.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
        setLoader(false);
      });
  };

  const [autoNumber, setAutoNumber] = useState("");

  // Automatically generate and set the tracking number when the component mounts
  useEffect(() => {
    setAutoNumber(generateTrackingNumber());
  }, []);

  // Function to handle button click to regenerate the tracking number
  const handleRegenerate = () => {
    setAutoNumber(generateTrackingNumber());
  };

  // Function to generate a random alphanumeric string of 10 characters
  const generateTrackingNumber = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let trackingNumber = "";
    for (let i = 0; i < 15; i++) {
      trackingNumber += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return trackingNumber;
  };
  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        className={"rounded-md w-[60%] bg-[#F4F6F9]"}
      >
        <div className="p-5">
          <div className="flex justify-between items-center">
            <div></div>
            <MdClose onClick={() => setIsModalOpen(false)} size={25} />
          </div>
          <h1 className="capitalize text-center h4 font-bold text-xl">
            Buy it for me
          </h1>
          <p className="text-center text-sm">
            Please fill in the form below to tell us which product you are
            interested in purchasing, including quantity etc.
          </p>
          <div className="bg-white mt-4">
            <Formik
              initialValues={{
                productUrl: "",
                productName: "",
                unitPrice: "",
                quantity: "",
                shippingFees: "",
                color: "",
                size: "",
                description: "",
                attachPictures: null,
                tracking: autoNumber,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue }) => (
                <Form>
                  <div className="items-center flex justify-center">
                    <div className="w-full py-4 border-b-2 border-[#A36A2D] text-center">
                      <span className="font-medium flex text-[#A36A2D] items-center justify-center gap-2">
                        <FaRegCircleCheck color="#A36A2D" /> Product Details
                      </span>
                    </div>
                    <div
                      className={`w-full py-4 ${
                        openState ? "border-b-2 border-[#A36A2D]" : ""
                      } text-center`}
                    >
                      <span
                        className={`font-medium flex ${
                          openState ? "text-[#A36A2D]" : ""
                        } items-center justify-center gap-2`}
                      >
                        <FaRegCircleCheck /> Checkout
                      </span>
                    </div>
                  </div>

                  {openState ? (
                    <div className="pt-4">
                      <label>Product Details</label>
                      <div className="flex mt-2 justify-between items-center rounded-md border p-3">
                        <div className="flex gap-4">
                          <div className="w-20 h-20 rounded-md overflow-hidden">
                            {formValues.image ? (
                              <img
                                src={formValues.image}
                                alt=""
                                className="w-full h-full"
                              />
                            ) : (
                              <img
                                src={require("../../assets/images/cart_image.png")}
                                alt=""
                                className="w-full h-full"
                              />
                            )}
                          </div>
                          <div>
                            <h2 className="text-black font-semibold text-lg">
                              {formValues.productName}
                            </h2>
                            <div className="flex gap-2">
                              <p className="m-0 text-sm">Quantity: </p>
                              <p className="m-0 text-sm">
                                {formValues.quantity}
                              </p>
                              <p className="m-0 text-sm">Color:</p>
                              <p className="m-0 text-sm">{formValues.color}</p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="text-black">
                            $ {formValues.unitPrice} x {formValues.quantity}
                          </span>
                        </div>
                      </div>

                      <div className="my-4">
                        <h1 className="text-2xl font-bold mb-4">
                          Payment Option
                        </h1>
                        <h6 className="font-semibold">Select a new method</h6>
                        <ul className="mt-4 bg-[#F4F6F9] border p-0">
                          {/* <li className="p-2.5">
                            <input type="radio" className="" />
                          </li> */}
                          {/* <hr /> */}
                          <li className="p-4 flex gap-5">
                            <input type="radio" className="" />
                            <img src={paypal} alt="" />
                          </li>
                          <li className=" bg-white p-4">
                            <PayPalScriptProvider
                              options={{
                                clientId:
                                  "AfLLUPimn73NAX2v5iCHU5ZlVN4wCOr0VIKjh2z1V_ptZMXfEgt_UhuRB_1oqFKrt0NzQyGF0T5dwpb7",
                              }}
                            >
                              <PayPalButtons style={{ layout: "vertical" }} />
                            </PayPalScriptProvider>
                          </li>
                        </ul>
                      </div>

                      <div className="my-5 border rounded-sm p-3">
                        <ul className="flex flex-col gap-2 p-0">
                          <li className="flex justify-between items-center">
                            <h6 className="text-black font-medium">Total</h6>
                            <h6 className="text-black font-medium">
                              $ {formValues.unitPrice * formValues.quantity}
                            </h6>
                          </li>
                          <li className="flex justify-between items-center">
                            <h6 className="text-black font-medium">
                              Shipping Fees
                            </h6>
                            <h6 className="text-black font-medium">
                              $ {formValues.shipFee}
                            </h6>
                          </li>
                          <hr />
                          <li className="flex justify-between items-center">
                            <h6 className="text-black text-xl font-medium">
                              Subtotal
                            </h6>
                            <h6 className="text-black text-xl font-medium">
                              ${" "}
                              {formValues.unitPrice * formValues.quantity +
                                parseFloat(formValues.shipFee)}
                            </h6>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="p-3 flex gap-3 flex-wrap">
                        <div className="ml-auto border  px-2 py-1   w-80 flex  justify-between items-center rounded-md">
                          <div>
                            <input
                              name="productUrl"
                              className="border rounded-md p-2"
                              disabled
                              value={autoNumber}
                            />
                          </div>
                          {/* <ErrorMessage
                            name="productUrl"
                            component="div"
                            className="text-red-500 text-sm"
                          /> */}

                          <button
                            type="button"
                            onClick={handleRegenerate}
                            className="    bg-[#A36A2D]   text-white rounded-md  p-2"
                          >
                            Regenerate
                          </button>
                        </div>
                        <div className="w-[100%]">
                          <label className="font-medium mb-3">
                            Product URL
                          </label>
                          <Field
                            name="productUrl"
                            className="border rounded-md mt-2 w-full p-3"
                          />
                          <ErrorMessage
                            name="productUrl"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <div className="w-[100%]">
                          <label className="font-medium mb-3">
                            Product Name
                          </label>
                          <Field
                            name="productName"
                            className="border rounded-md mt-2 w-full p-3"
                          />
                          <ErrorMessage
                            name="productName"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <div className="grid grid-cols-5 gap-4">
                          <div className="">
                            <label className="font-medium mb-3">
                              Unit Price
                            </label>
                            <Field
                              name="unitPrice"
                              type="number"
                              className="border rounded-md mt-2 w-full p-3"
                            />
                            <ErrorMessage
                              name="unitPrice"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                          <div className="">
                            <label className="font-medium mb-3">Quantity</label>
                            <Field
                              name="quantity"
                              type="number"
                              className="border rounded-md mt-2 w-full p-3"
                            />
                            <ErrorMessage
                              name="quantity"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                          <div className="">
                            <label className="font-medium mb-3">
                              Shipping Fees
                            </label>
                            <Field
                              name="shippingFees"
                              type="number"
                              className="border rounded-md mt-2 w-full p-3"
                            />
                            <ErrorMessage
                              name="shippingFees"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                          <div className="">
                            <label className="font-medium mb-3">Color</label>
                            <Field
                              name="color"
                              className="border rounded-md mt-2 w-full p-3"
                            />
                            <ErrorMessage
                              name="color"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                          <div className="">
                            <label className="font-medium mb-3">Size</label>
                            <Field
                              name="size"
                              className="border rounded-md mt-2 w-full p-3"
                            />
                            <ErrorMessage
                              name="size"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                        </div>
                        <div className="w-[100%]">
                          <label className="font-medium mb-3">
                            Attach Pictures
                          </label>
                          <input
                            type="file"
                            name="attachPictures"
                            onChange={(event) => {
                              setFieldValue(
                                "attachPictures",
                                event.target.files[0]
                              );
                            }}
                            className="border rounded-md mt-2 w-full py-3"
                          />
                        </div>
                        <div className="w-[100%]">
                          <label className="font-medium mb-3">
                            Description
                          </label>
                          <Field
                            name="description"
                            as="textarea"
                            rows={4}
                            className="w-full mt-2 border p-3 rounded-md"
                          />
                        </div>

                        <div className="flex justify-end items-center ml-auto">
                          {loading === true ? (
                            <button
                              disabled
                              type="button"
                              class="px-6 text-center font-semibold text-sm uppercase rounded-sm py-2.5 flex items-center justify-center gap-1 bg-[#A36A2D] text-white"
                            >
                              <svg
                                aria-hidden="true"
                                role="status"
                                class="inline w-4 h-4 me-3 text-white animate-spin"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="#E5E7EB"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentColor"
                                />
                              </svg>
                              Loading...
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="px-6 text-center font-semibold text-sm uppercase rounded-sm py-2.5 flex items-center justify-center gap-1 bg-[#A36A2D] text-white"
                            >
                              Place Orders
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BuyForModal;
