import React, { useState } from "react";
import { Base_url } from "../../utils/Base_url";
import axios from "axios";
import moment from "moment/moment";

const TrackShipmentComponent = () => {
  const [activeButton, setActiveButton] = useState("number");
  const [searchTracking, setSearchTracking] = useState("");
  const [orderDetails, setOrderDetails] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
const [orderBook,setOrdersBook] = useState(null);
  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  const submitFun = async () => {
    try {
      const res = await axios.get(`${Base_url}/order/track/${searchTracking}`);
      console.log(res);
      const orderBook = res.data.book
      const orderData = res.data.data;
      setOrderDetails(orderData);

      setOrdersBook(orderBook)

      // Update currentStep based on order status
      const stepMap = {
        approved: 1,
        processing: 2,
        shipped: 3,
        delivered: 4,
      };
      setCurrentStep(stepMap[orderData.status.toLowerCase()] || 1);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 4));
  };

  return (
    <div>
      <div className="container pt-14 mx-auto">
        <div className="text-center">
          <h1 className="text-[#A36A2D] sm:text-5xl text-4xl font-Asap font-semibold">
            TRACK A SHIPMENT
          </h1>
          <p className="text-black sm:text-lg text-md font-Asap font-semibold">
            Track your LTL, truckload or intermodal shipment by entering your
            Track number below to get instant freight tracking information.
          </p>
        </div>

        <div className="sm:w-[50%] w-[90%] mx-auto py-10">
          <div className="  flex   gap-3 items-center mb-6">
            <button
              className={`px-7  py-3 text-center text-sm ${
                activeButton === "number"
                  ? "bg-[#A36A2D] text-white"
                  : "hover:bg-[#A36A2D] hover:text-white border-2 text-[#A36A2D] font-medium border-[#A36A2D]"
              } uppercase rounded-sm  flex items-center justify-center gap-1`}
              onClick={() => handleButtonClick("number")}
            >
              Track by Number
            </button>
            <button
              className={`px-7 py-3 text-sm ${
                activeButton === "reference"
                  ? "bg-[#A36A2D] text-white"
                  : "hover:bg-[#A36A2D] hover:text-white border-2 text-[#A36A2D] font-medium border-[#A36A2D]"
              } uppercase rounded-sm`}
              onClick={() => handleButtonClick("reference")}
            >
              Track by Reference
            </button>
          </div>

          <div className="flex gap-3 bg-white shadow-xl p-8 border rounded-md">
            <input
              value={searchTracking}
              onChange={(e) => setSearchTracking(e.target.value)}
              placeholder="Track ID"
              className="text-gray border outline-none p-3 w-full"
            />
            <button
              onClick={() => submitFun()}
              className="bg-[#A36A2D] text-white px-10 text-sm rounded-sm py-2"
            >
              TRACK
            </button>
          </div>
        </div>
      </div>

      {orderDetails && (
        <div className=" min-h-screen flex items-center justify-center">
          <div className="container mx-auto p-4">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h2 className="text-2xl font-semibold mb-4">Product Tracking</h2>

    
              {/* Order Details */}
              <div className="bg-gray-50 p-4 rounded-lg shadow-inner mb-4">
                {/* <h3 className="text-xl font-semibold mb-2">Order Details</h3> */}
                <div className="flex flex-col pt-3 md:flex-row  justify-center mb-4">
                  <div>
                    <p className="text-gray-600  font-medium">
                      <span className="font-semibold text-black">
                        Order ID :{" "}
                      </span>{" "}
                      {orderDetails._id}
                    </p>
                    <p className="text-gray-600 font-medium">
                      <span className="font-semibold text-black">
                        Date Placed:
                      </span>{" "}
                      {moment(orderDetails.createdAt).format("DD-MM-YYYY")}
                    </p>
                  </div>
                  {/* <div className=" ">
                  <p className="text-gray-600 mb-3 font-medium">
                      <span className="font-semibold  bg-orange-600 rounded-full  px-5 py-2 text-black">{orderDetails?.tracking?.status}</span>
                      
                    </p>  
                    <p className="text-gray-600 font-medium">
                      <span className="font-semibold  text-black">Total:</span>{" "}
                      ${orderDetails.unitPrice}
                    </p>
                    <p className="text-gray-600 font-medium">
                      <span className="font-semibold text-black">
                        Shipping Address:
                      </span>{" "}
                      {orderDetails.shipFee}
                    </p>
                  </div> */}

                 
                </div>
        {/* Tracking Steps */}
<div className="relative flex mx-auto bg-white shadow-md rounded-md w-[60%] py-12 flex-col items-center mb-8">
  {/* Background Lines */}

  {/* Steps */}
  {orderBook?.allStatus?.map((item, index) => (
    <div className="flex  items-center pl-20 mb-4 relative w-full" key={index}>
      {/* Vertical line */}
      {index < orderBook?.allStatus.length - 1 && (
        <div
        className={`absolute top-2  transform translate-x-4 w-1 ${index < orderBook?.allStatus.length - 1 ? "bg-[#A36A2D]" : "bg-transparent"}`}
        style={{ height: 'calc(100% + 1.5rem)' }} 
      />
      )}

      {/* Step container */}
      <div className="flex items-center z-20  w-full">
        {/* Step circle */}
        <div
          className={`rounded-full h-8 w-8 flex items-center justify-center mb-2 border-2 ${
            currentStep >= index + 1 ? "border-[#A36A2D] bg-[#A36A2D] text-white" : "border-[#A36A2D] bg-[#A36A2D] text-white"
          }`}
        >
          {index + 1}
        </div>

        {/* Step details */}
        <div className="ml-4">
          <p className="text-lg font-semibold text-black">{item?.name}</p>
          <p className="text-black">
            {moment(item?.date).format('DD-MM-YYYY, h:mm:ss a')}
          </p>
        </div>
      </div>
    </div>
  ))}
</div>
                <h3 className="text-xl font-semibold mb-2">Product Item</h3>
                <ul>
                  <li className="flex justify-between items-center py-2 border-b border-gray-200">
                    <div className=" flex   gap-4">
                      <div className=" w-20 h-20 rounded-md    overflow-hidden">
                        {orderDetails?.image ? (
                          <img
                            src={orderDetails?.image}
                            className=" w-full h-full"
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("../../assets/images/cart_image.png")}
                            className=" w-full h-full"
                            alt=""
                          />
                        )}
                      </div>
                      <div>
                        <span className="text-gray-700 text-lg font-semibold">
                          {orderDetails.productName}
                        </span>
                        <div className=" flex flex-col">
                          <span className=" font-medium">
                            Color: {orderDetails?.color}{" "}
                          </span>
                          <span className=" font-medium">
                            Size: {orderDetails?.size}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <span className="text-gray-600 font-medium text-lg">
                      ${orderDetails.unitPrice}
                    </span>
                  </li>
                </ul>

                <div>
                  <h3 className="text-xl font-semibold mb-2 pt-3">
                    Description{" "}
                  </h3>
                  <p>{orderDetails?.comment}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrackShipmentComponent;
