import React, { useState } from "react";
import { AiOutlineGlobal } from "react-icons/ai";
import { FaCalculator, FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import TranslateComponent from "../translator/TranslatorComponent";

const languages = [
  { code: 'en', name: 'English' },
  { code: 'ra', name: 'Arabic' },
  { code: 'fa', name: 'Farsi' },
  { code: 'ur', name: 'Urdu' },
];

const TopNav = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('English'); // State to manage selected language

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language.name);
    setDropdownOpen(false);
  };

  return (
    <div className="bg-[#262829] py-3.5">
      <div className="container flex sm:flex-row  gap-4 flex-col justify-between items-center mx-auto">
        <div>
          <ul className="flex items-center gap-4 p-0">
            <li>
              <p className="font-semibold text-white">Follow us:</p>
            </li>
            <li>
              <Link to="/">
                <p className="m-0 rounded-full flex shadow-xl justify-center items-center w-9 h-9 bg-white">
                  <FaFacebookF color="#A46A2D" />
                </p>
              </Link>
            </li>
            <li>
              <Link to="/">
                <p className="m-0 rounded-full shadow-xl flex justify-center items-center w-9 h-9 bg-white">
                  <FaTwitter color="#A46A2D" />
                </p>
              </Link>
            </li>
            <li>
              <Link to="/">
                <p className="m-0 rounded-full flex shadow-xl justify-center items-center w-9 h-9 bg-white">
                  <FaLinkedinIn color="#A46A2D" />
                </p>
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex gap-8 items-center">
          <Link to="" className="flex font-semibold  hover:text-[#A36A2D] text-sm text-white items-center gap-2">
            <FaCalculator color="#A46A2D" size={20} />
            RATE CALCULATOR
          </Link>
          <div className="relative inline-block text-left">
            {/* <button
              onClick={toggleDropdown}
              className="flex items-center gap-2 font-semibold text-sm text-white"
            >
              <AiOutlineGlobal color="#A46A2D" size={25} />
              {selectedLanguage} {/* Display the selected language
            </button>
            {dropdownOpen && (
              <div
                className="absolute  z-50  right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <div className="py-1">
                  {languages.map((language) => (
                    <div
                      key={language.code}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      role="menuitem"
                      onClick={() => handleLanguageChange(language)}
                    >
                      {language.name}
                      
                    </div>
                  ))}
                </div>
              </div>
            )} */}


            <TranslateComponent/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
